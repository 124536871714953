import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageLocation from '../PageLocation';
import './styles.css';
import ApprContext from '../../ApprStore/appr-context';
import { getFormattedDate } from '../../utility/Utility';
import { IApprFormPaxHistoryTbl } from '../../models';
import { useOktaAuth } from "@okta/okta-react";

interface IProps {
    readOnly?: boolean;
}

const ApprAdminPaxEdit = (props: IProps) => {

    const { formId, apprFormPaxId } = useParams<{ formId: string, apprFormPaxId: string }>();
    const navigate = useHistory();
    const apprContext = useContext(ApprContext);
    const [getPaxIsLoading, setGetPaxIsLoading] = useState(false);
    const [getPaxApiError, setgetPaxApiError] = useState<string | null>(null);
    const [getPaxHistoryIsLoading, setGetPaxHistoryIsLoading] = useState(false);
    const [getPaxHistoryApiError, setgetPaxHistoryApiError] = useState<string | null>(null);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [saveApiError, setSaveApiError] = useState<string | null>(null);
    const { authState, oktaAuth } = useOktaAuth();

    useEffect(() => {
        if (apprContext.apprFormPax && apprContext.apprFormPax[0]?.apprFormId.toString() == formId) {
            apprContext.setSingleApprFormPax(apprContext.apprFormPax.filter(element => element.id.toString() == apprFormPaxId)[0]);
            apprContext.setSingleApprFormPaxReference(apprContext.apprFormPax.filter(element => element.id.toString() == apprFormPaxId)[0]);
        }
        else
            getApprPaxById();


        if (formId && apprContext.apprForm.id.toString() != formId)
            apprContext.getApprFormData(formId);


        if (!apprContext.formPaxHistory || apprContext.formPaxHistory[0]?.apprFormPaxId.toString() != apprFormPaxId)
            getApprPaxHistoryById();

    }, []);


    const getApprPaxById = () => {
        setGetPaxIsLoading(true);
        setgetPaxApiError("");
        fetch(`${process.env.REACT_APP_APPR_FDC_API_URL}/ApprFormPax/${apprFormPaxId}`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json();
            })
            .then(data => {
                apprContext.setSingleApprFormPax(data.apprFormPax);
                apprContext.setSingleApprFormPaxReference(data.apprFormPax);
                apprContext.setApprBookingType(data.apprBookingType);
                apprContext.setApprClaimStatus(data.apprClaimStatus);
                apprContext.setApprClaimType(data.apprClaimType);
                apprContext.setApprCompensationType(data.apprCompensationType);
                apprContext.setApprPaymentMethod(data.apprPaymentMethod);
                setGetPaxIsLoading(false);
                setgetPaxApiError("");
            })
            .catch((error) => {
                setGetPaxIsLoading(false);
                setgetPaxApiError("Something went Wrong !");
            });
    }

    const getApprPaxHistoryById = () => {
        setGetPaxHistoryIsLoading(true);
        setgetPaxHistoryApiError("");
        fetch(`${process.env.REACT_APP_APPR_FDC_API_URL}/ApprFormPax/${apprFormPaxId}/History`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json();
            })
            .then(data => {
                apprContext.setFormPaxHistory(data);
                setGetPaxHistoryIsLoading(false);
                setgetPaxHistoryApiError("");
            })
            .catch((error) => {
                setGetPaxHistoryIsLoading(false);
                setgetPaxHistoryApiError("Something went Wrong !");
            });
    }

    const showData = (): boolean => {
        return !getPaxIsLoading && !getPaxHistoryIsLoading && !apprContext.mainApprLoading &&
            !getPaxApiError && !getPaxHistoryApiError && !apprContext.mainApprApiError;
    }

    const saveApprFormPax = () => {
        setSaveIsLoading(true);
        let apprFormPax = apprContext.singleApprFormPax;
        let newPaxHistory: IApprFormPaxHistoryTbl = {
            apprFormPaxId: apprContext.singleApprFormPax?.id || 0,
            processing: "",
            submittedDate: new Date(),
            submittedBy: authState?.idToken?.claims['email'] || "",
        };

        if (apprContext.singleApprFormPax?.claimStatus != apprContext.singleApprFormPaxReference?.claimStatus)
            newPaxHistory.processing = newPaxHistory.processing + `Update Claim Status as ${apprContext.apprClaimStatus?.filter(e => e.id == apprContext.singleApprFormPax?.claimStatus)[0]?.description}; `;

        if (apprContext.singleApprFormPax?.comments != apprContext.singleApprFormPaxReference?.comments)
            newPaxHistory.processing = newPaxHistory.processing + `Update Comments as ${apprContext.singleApprFormPax?.comments}; `;

        if (apprContext.singleApprFormPax?.claimVoucherRedeemed != apprContext.singleApprFormPaxReference?.claimVoucherRedeemed)
            newPaxHistory.processing = newPaxHistory.processing + `Update Claim Voucher Redeemed as ${apprContext.singleApprFormPax?.claimVoucherRedeemed}; `;

        if (apprContext.singleApprFormPax?.birthDate != apprContext.singleApprFormPaxReference?.birthDate)
            newPaxHistory.processing = newPaxHistory.processing + `Update Birth Date as ${apprContext.singleApprFormPax?.birthDate}; `;

        if (apprContext.singleApprFormPax?.address != apprContext.singleApprFormPaxReference?.address)
            newPaxHistory.processing = newPaxHistory.processing + `Update Address as ${apprContext.singleApprFormPax?.address}; `;

        if (apprContext.singleApprFormPax?.city != apprContext.singleApprFormPaxReference?.city)
            newPaxHistory.processing = newPaxHistory.processing + `Update City as ${apprContext.singleApprFormPax?.city}; `;

        if (apprContext.singleApprFormPax?.province != apprContext.singleApprFormPaxReference?.province)
            newPaxHistory.processing = newPaxHistory.processing + `Update Province as ${apprContext.singleApprFormPax?.province}; `;

        if (apprContext.singleApprFormPax?.postCode != apprContext.singleApprFormPaxReference?.postCode)
            newPaxHistory.processing = newPaxHistory.processing + `Update Post Code as ${apprContext.singleApprFormPax?.postCode}; `;

        fetch(`${process.env.REACT_APP_APPR_FDC_API_URL}/ApprFormPax`, {
            method: 'POST',
            body: JSON.stringify({ apprFormPax, newPaxHistory }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`
            },
        }).then(res => {
            if (!res.ok) {
                throw new Error('Something went wrong! Please try again');
            }
            return res.json();
        })
            .then(async (data) => {
                apprContext.setFormPaxHistory((prevState) => prevState ? ([...prevState, data]) : null)
                apprContext.setSingleApprFormPaxReference(apprContext.singleApprFormPax);
                apprContext.setApprFormPax((prevState) => prevState ?
                    [...prevState.map(e => {
                        if (e.id == apprContext.singleApprFormPax?.id) {
                            return { ...apprContext.singleApprFormPax };
                        }
                        return e;
                    })]
                    : null);
                setSaveIsLoading(false);
                setSaveApiError("");
                navigate.goBack();
            })
            .catch((error) => {
                setSaveIsLoading(false);
                setSaveApiError("Something went Wrong !");
            });;
    }

    const objectHasNotChanged = (): boolean => {
        return apprContext.singleApprFormPax?.birthDate != null && apprContext.singleApprFormPaxReference?.birthDate != null &&
            getFormattedDate(new Date(apprContext.singleApprFormPax?.birthDate)) == getFormattedDate(new Date(apprContext.singleApprFormPaxReference?.birthDate)) &&
            apprContext.singleApprFormPax?.address == apprContext.singleApprFormPaxReference?.address &&
            apprContext.singleApprFormPax?.city == apprContext.singleApprFormPaxReference?.city &&
            apprContext.singleApprFormPax?.province == apprContext.singleApprFormPaxReference?.province &&
            apprContext.singleApprFormPax?.postCode == apprContext.singleApprFormPaxReference?.postCode &&
            apprContext.singleApprFormPax?.claimStatus == apprContext.singleApprFormPaxReference?.claimStatus &&
            apprContext.singleApprFormPax?.claimVoucherRedeemed == apprContext.singleApprFormPaxReference?.claimVoucherRedeemed &&
            apprContext.singleApprFormPax?.comments == apprContext.singleApprFormPaxReference?.comments
    }


    return (
        <>
            {(getPaxIsLoading || getPaxHistoryIsLoading || apprContext.mainApprLoading) &&
                <div className="container-fluid">
                    <div className="row g-0 d-flex justify-content-center">
                        <div className="spinner-border m-5" role="status"> </div>
                    </div>
                </div>
            }

            {(getPaxApiError || getPaxHistoryApiError || apprContext.mainApprApiError) &&
                <div className="container-fluid create-form mt-4">
                    <div className="col">
                        <div className="row">
                            <div className="alert alert-danger" role="alert">
                                Something Went Wrong !
                            </div>
                        </div>
                    </div>
                </div>}

            {showData() &&
                <><div className="container-fluid  d-flex justify-content-center create-form-apprpaxedit mt-4">
                    <div className="col p-0">

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Flight Date (local)(dd/mm/yyyy):</strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label>{getFormattedDate(new Date(apprContext.apprForm.flightDateLocal))}</label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Flight Number: </strong> </label>
                            </div>
                            <div className="col-sm-3">
                                <label>{apprContext.apprForm.flightNumber} {apprContext.apprForm.dep}-{apprContext.apprForm.arr} </label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Booking Number: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label> {apprContext.singleApprFormPax?.bookingNumber} </label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>First Name: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label>{apprContext.singleApprFormPax?.firstName} </label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Last Name: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label>{apprContext.singleApprFormPax?.lastName}  </label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Booking Type: </strong> </label>
                            </div>
                            <div className="col-sm-3">
                                <label>{apprContext.apprBookingType?.filter(e => e.id == apprContext.singleApprFormPax?.bookingType)[0]?.description}</label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Birth Date from Booking: </strong> </label>
                            </div>
                            <div className="col-sm-2">
                                <input
                                    type="date"
                                    value={apprContext.singleApprFormPax?.birthDate ? getFormattedDate(new Date(apprContext.singleApprFormPax.birthDate)) : ""}
                                    className="form-control form-control-sm"
                                    onChange={(e) => apprContext.setSingleApprFormPax((prevState) => prevState ? ({ ...prevState, birthDate: new Date(e.target.value.replace('-', '/')) }) : null)}
                                    disabled={props.readOnly}
                                />
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Birth Date Pax Submitted: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label>{apprContext.singleApprFormPax?.birthDatePaxSubmitted ? getFormattedDate(new Date(apprContext.singleApprFormPax.birthDatePaxSubmitted)) : ""}</label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Claim Type: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label>{apprContext.apprClaimType?.filter(e => e.id == apprContext.singleApprFormPax?.claimType)[0]?.description}</label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Compensation Type: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label>{apprContext.apprCompensationType?.filter(e => e.id == apprContext.singleApprFormPax?.compensationType)[0]?.description}</label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Compensation Amount: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                {apprContext.apprForm?.flightType == 1 && apprContext.singleApprFormPax?.claimType == 2 && apprContext.singleApprFormPax?.compensationType == 1 || apprContext.singleApprFormPax?.claimType == 1 && apprContext.singleApprFormPax?.compensationType == 1 ? <label>{apprContext.apprForm.mcAmount}</label> : ""}
                                {apprContext.apprForm?.flightType == 1 && apprContext.singleApprFormPax?.claimType == 2 && apprContext.singleApprFormPax?.compensationType == 2 || apprContext.singleApprFormPax?.claimType == 1 && apprContext.singleApprFormPax?.compensationType == 2 ? <label>{apprContext.apprForm.voucherAmount}</label> : ""}
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Email: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label>{apprContext.singleApprFormPax?.email}  </label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Phone: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label>{apprContext.singleApprFormPax?.phone}  </label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Payment Method: </strong> </label>
                            </div>
                            <div className="col-sm-1">
                                <label>{apprContext.apprPaymentMethod?.filter(e => e.id == apprContext.singleApprFormPax?.paymentMethod)[0]?.description}</label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Address: </strong> </label>
                            </div>
                            <div className="col-sm-2">
                                <input
                                    type="text"
                                    value={apprContext.singleApprFormPax?.address || ""}
                                    className="form-control form-control-sm"
                                    onChange={(e) => apprContext.setSingleApprFormPax((prevState) => prevState ? ({ ...prevState, address: e.target.value }) : null)}
                                    disabled={props.readOnly}
                                />
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>City: </strong> </label>
                            </div>
                            <div className="col-sm-2">
                                <input
                                    type="text"
                                    value={apprContext.singleApprFormPax?.city || ""}
                                    className="form-control form-control-sm"
                                    onChange={(e) => apprContext.setSingleApprFormPax((prevState) => prevState ? ({ ...prevState, city: e.target.value }) : null)}
                                    disabled={props.readOnly}
                                />
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Province </strong> </label>
                            </div>
                            <div className="col-sm-2">
                                <input
                                    type="text"
                                    value={apprContext.singleApprFormPax?.province || ""}
                                    className="form-control form-control-sm"
                                    onChange={(e) => apprContext.setSingleApprFormPax((prevState) => prevState ? ({ ...prevState, province: e.target.value }) : null)}
                                    disabled={props.readOnly}
                                />
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Post Code </strong> </label>
                            </div>
                            <div className="col-sm-2">
                                <input
                                    type="text"
                                    value={apprContext.singleApprFormPax?.postCode || ""}
                                    className="form-control form-control-sm"
                                    onChange={(e) => apprContext.setSingleApprFormPax((prevState) => prevState ? ({ ...prevState, postCode: e.target.value }) : null)}
                                    disabled={props.readOnly}
                                />
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Confirmation No: </strong> </label>
                            </div>
                            <div className="col-sm-3">
                                <label>{apprContext.singleApprFormPax?.claimConfirmationNo} </label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Claim Create Date: </strong> </label>
                            </div>
                            <div className="col-sm-3">
                                <label>{apprContext.singleApprFormPax?.claimCreateDate ? (new Date(apprContext.singleApprFormPax.claimCreateDate)).toLocaleString() : ""}</label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label><strong>Claim Submitted By: </strong> </label>
                            </div>
                            <div className="col-sm-3">
                                <label>{apprContext.singleApprFormPax?.submittedBy} </label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6 p-3">
                                <div className="h6">----For Accounting Team Using Only---- </div>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label className="col-form-label"><strong>Claim Status:</strong> </label>
                            </div>
                            <div className="col-sm-2">
                                <select
                                    disabled={props.readOnly}
                                    className="form-select form-select-sm"
                                    value={apprContext.singleApprFormPax?.claimStatus || ""}
                                    onChange={(e) => apprContext.setSingleApprFormPax((prevState) => prevState ? ({ ...prevState, claimStatus: +e.target.value, }) : null)}>
                                    {apprContext.apprClaimStatus && apprContext.apprClaimStatus.map((element, index) => <option key={index} value={element.id || ''}>{element.description}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label className="col-form-label"><strong>Claim Updated Date:</strong> </label>
                            </div>
                            <div className="col-sm-3">
                                <label>{apprContext.singleApprFormPax?.claimStatusUpdateDate ? (new Date(apprContext.singleApprFormPax.claimStatusUpdateDate).toLocaleString()) : ""}</label>
                            </div>
                        </div>

                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label className="col-form-label"><strong>Claim Voucher Redeemed: </strong> </label>
                            </div>
                            <div className="col-sm-2">
                                {[{ value: "1", description: "Yes" }, { value: "0", description: "No" }].map((element, index) => <div className="form-check form-check-inline" key={index}>
                                    <input
                                        disabled={props.readOnly}
                                        className="form-check-input"
                                        value={element.value}
                                        checked={apprContext.singleApprFormPax?.claimVoucherRedeemed == element.value}
                                        type="radio"
                                        name="ClaimVoucherRedeemed"
                                        onChange={(e) => apprContext.setSingleApprFormPax((prevState) => prevState ? ({ ...prevState, claimVoucherRedeemed: e.target.value }) : null)} />
                                    <label className="form-check-label">
                                        {element.description}
                                    </label>
                                </div>
                                )}
                            </div>
                        </div>


                        <div className="row g-0">
                            <div className="col-sm-3">
                                <label className="col-form-label"><strong>Comments: </strong> </label>
                            </div>
                            <div className="col-sm-8">
                                <input
                                    disabled={props.readOnly}
                                    type="text"
                                    value={apprContext.singleApprFormPax?.comments || ""}
                                    className="form-control form-control-sm"
                                    onChange={(e) => apprContext.setSingleApprFormPax((prevState) => prevState ? ({ ...prevState, comments: e.target.value }) : null)}
                                />
                            </div>
                        </div>


                        <div className="row">
                            <div className="col mt-2 p-0 d-flex justify-content-center">
                                <button disabled={props.readOnly || objectHasNotChanged() || saveIsLoading} onClick={saveApprFormPax} className="btn custom-button btn-sm me-2">
                                    {saveIsLoading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                                    {!saveIsLoading && <span>Submit</span>}
                                </button>
                                <button onClick={() => navigate.goBack()} className="btn custom-button btn-sm me-2">Cancel</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col mt-2 d-flex justify-content-sm-center">
                                <p className="danger"> {saveApiError}</p>
                            </div>
                        </div>

                    </div>
                </div><div className="container-fluid table-apprpaxedit mt-4">
                        <div className="row g-0 d-flex justify-content-center">
                            <div className="col p-0">
                                <div className="h5">History:</div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <thead className=" custom-color-head">
                                            <tr>
                                                <th scope="col">IssuedDate </th>
                                                <th scope="col">IssuedBy </th>
                                                <th scope="col">Processing </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {apprContext.formPaxHistory && apprContext.formPaxHistory.map((element, index) => <tr key={index}>
                                                <td>{new Date(element.submittedDate).toLocaleString()} </td>
                                                <td>{element.submittedBy} </td>
                                                <td>{element.processing} </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div></>}
        </>
    )
}

export default ApprAdminPaxEdit;