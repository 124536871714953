import { faFileText, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageLocation from '../PageLocation';
import './styles.css';
import ApprContext from '../../ApprStore/appr-context';
import { getFormattedDate } from '../../utility/Utility';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react";
import { IApprFormPaxTbl } from '../../models';

interface IProps {
    readOnly?: boolean;
  }

const ApprAdminPax = (props: IProps) => {

    const apprContext = useContext(ApprContext);
    const [addNewPax, setAddNewPax] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [apiError, setApiError] = useState<string | null>(null);
    const { flightDateLocal, dep, arr, flightNumber, formId } = useParams<{ flightDateLocal: string, dep: string, arr: string, flightNumber: string, formId: string }>();
    const navigate = useHistory();
    const { authState, oktaAuth } = useOktaAuth();


    useEffect(() => {
        if (!apprContext.apprFormPax || apprContext.apprFormPax[0]?.apprFormId.toString() != formId || formId == "-1" )
            getApprPax();
        else
            setIsLoading(false);
    }, []);

    const getApprPax = () => {
        setIsLoading(true);
        setApiError("");
        fetch(`${process.env.REACT_APP_APPR_FDC_API_URL}/ApprFormPax?flightDate=${flightDateLocal}&dep=${dep}&arr=${arr}&flightNumber=${flightNumber}&formId=${formId}`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json();
            })
            .then(data => {
                apprContext.setApprFormPax(data.apprFormPax.sort((a: IApprFormPaxTbl, b: IApprFormPaxTbl) => b.id - a.id));
                apprContext.setApprBookingType(data.apprBookingType);
                apprContext.setApprClaimStatus(data.apprClaimStatus);
                apprContext.setApprClaimType(data.apprClaimType);
                apprContext.setApprCompensationType(data.apprCompensationType);
                apprContext.setApprPaymentMethod(data.apprPaymentMethod);
                setIsLoading(false);
                setApiError("");
            })
            .catch((error) => {
                setApiError("Something Went Wrong !");
                setIsLoading(false);
            });
    }


    return (
        <>
            <div className="container-fluid  d-flex justify-content-center filter mt-4">
                <div className="col d-flex  flex-column flex-lg-row justify-content-around pt-2 pb-2">
                    <span>Flight Date (local): {flightDateLocal ? getFormattedDate(new Date(flightDateLocal)) : ""}</span>
                    <span>Flight Number: {flightNumber} {dep}-{arr}</span>
                    <div>
                        <button disabled={isLoading} onClick={() => navigate.goBack()} type="button" className="btn btn-light me-1">Back</button>
                        <button disabled={isLoading} onClick={() => getApprPax()} type="button" className="btn btn-light me-1"><FontAwesomeIcon icon={faRefresh} /></button>
                        {/*<button onClick={() => setAddNewPax(prevState => !prevState)} type="button" className="btn btn-light me-1">Add New Pax</button>*/}
                    </div>

                </div>
            </div>

            {addNewPax && <div className="container-fluid  d-flex justify-content-center filter mt-4">
                <div className="col p-2">
                    <form>
                        <div className="form-group d-flex  flex-column flex-lg-row mb-2">
                            <div className="col-2 m-0 p-0">
                                <label>Booking Number:* </label>
                            </div>
                            <div className="col-sm-6 m-0 p-0">
                                <input type="email" className="form-control" id="email" />
                            </div>
                        </div>

                        <div className="form-group d-flex  flex-column flex-lg-row mb-2">
                            <div className="col-2 m-0 p-0">
                                <label>First Name:* </label>
                            </div>
                            <div className="col-sm-6 m-0 p-0">
                                <input type="email" className="form-control" id="email" />
                            </div>
                        </div>

                        <div className="form-group d-flex  flex-column flex-lg-row mb-2">
                            <div className="col-2 m-0 p-0">
                                <label className="">Last Name:* </label>
                            </div>
                            <div className="col-sm-6 m-0 p-0">
                                <input type="email" className="form-control" id="email" />
                            </div>
                        </div>

                        <div className="form-group d-flex  flex-column flex-lg-row mb-2">
                            <div className="col-2 m-0 p-0">
                                <label className="">Birth Date:* </label>
                            </div>
                            <div className="col-sm-6 m-0 p-0">
                                <input type="email" className="form-control" id="email" />
                            </div>
                        </div>


                        <button type="submit" className="btn custom-button me-1">Save</button>
                        <button type="submit" className="btn custom-button me-1">Cancel</button>
                    </form>

                </div>
            </div>}

            {isLoading && <div className="container-fluid">
                <div className="row g-0 d-flex justify-content-center">
                    <div className="spinner-border m-5" role="status"> </div>
                </div>
            </div>}


            <div className="container-fluid  mt-4">
                <div className="row g-0 d-flex justify-content-center table">
                    <div className="col">
                        {apprContext.apprFormPax && !apprContext.apprFormPax.length && !apiError && !isLoading &&
                            <div className="alert alert-warning">No Pax found</div>}
                        {apiError && <div className="alert alert-danger">{apiError}</div>}
                        {apprContext.apprFormPax && apprContext.apprFormPax.length != 0 &&
                            !apiError && !isLoading &&
                            <div className="table-responsive">
                                <table className="table table-striped table-sm">
                                    <thead className=" custom-color-head">
                                        <tr>
                                            <th scope="col">Booking Number </th>
                                            <th scope="col">First Name </th>
                                            <th scope="col">Last Name </th>
                                            <th scope="col">Birth Date(mm/dd/yyyy)</th>
                                            <th scope="col">Claim Confirmation # </th>
                                            <th scope="col">Claim Status </th>
                                            <th scope="col">Review Claim </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {apprContext.apprFormPax && apprContext.apprFormPax.map((element, index) =>

                                            <tr key={index}>
                                                <td>{element.bookingNumber} </td>
                                                <td>{element.firstName} </td>
                                                <td>{element.lastName}</td>
                                                <td>{element.birthDate ? getFormattedDate(new Date(element.birthDate)) : ""}</td>
                                                <td>{element.claimConfirmationNo}</td>
                                                <td>{element.claimStatus > 1 && apprContext.apprClaimStatus?.filter(e => e.id == element.claimStatus)[0]?.description}</td>
                                                {props.readOnly ?
                                                <td>{element.claimStatus > 1 && <Link className="h6" to={`/appradminPaxEditreadonly/${element.apprFormId}/${element.id}`}> <FontAwesomeIcon icon={faFileText} /></Link>}</td>:
                                                <td>{element.claimStatus > 1 && <Link className="h6" to={`/appradminPaxEdit/${element.apprFormId}/${element.id}`}> <FontAwesomeIcon icon={faFileText} /></Link>}</td>
                                                }
                                                </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApprAdminPax;