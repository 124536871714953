import React, { useState, useEffect, SetStateAction } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import {
  IDelayFlightSearchResult,
  IFlightDelayFilter,
  IFlightDelayTimeDropdownItems,

} from '../models';


interface IProps {
  children?: React.ReactNode

}



//All Functions Or States Used In More Than a File Are In this Context.
export type afdnContextType = {
  delayFlightList: IDelayFlightSearchResult[] | null;
  setDelayFlightList: (value: SetStateAction<IDelayFlightSearchResult[] | null>) => void,
  flightDelayTimeDropdownItems: IFlightDelayTimeDropdownItems[] | null;
  flightDelayFilter: IFlightDelayFilter;
  setFlightDelayFilter: (value: SetStateAction<IFlightDelayFilter>) => void,
  delayFlightListFiltered: IDelayFlightSearchResult[] | null;
  searchIsloading: boolean;
  delayTimeItemsLoading: boolean;
  searchError: string;
  delayTimeItemsError: string;
  getFlightDelayTimeDropdownItems: () => void,
  getFlights: () => void,
  resetFilters: () => void,

};

const defaultContext = {
  delayFlightList: null,
  setDelayFlightList: () => { },
  flightDelayTimeDropdownItems: null,
  flightDelayFilter: { appliedDate: "", date: "", reg: "", delayTime: 0, flightNumber: "" },
  setFlightDelayFilter: () => { },
  delayFlightListFiltered: null,
  searchIsloading: false,
  delayTimeItemsLoading: false,
  searchError: "",
  delayTimeItemsError: "",
  getFlightDelayTimeDropdownItems: () => { },
  getFlights: () => { },
  resetFilters: () => { },




}
const AfdnContext = React.createContext<afdnContextType>(defaultContext);

export const AfdnContextProvider = (props: IProps) => {

  const { authState, oktaAuth } = useOktaAuth();
  const [delayFlightList, setDelayFlightList] = useState<IDelayFlightSearchResult[] | null>(null);
  const [flightDelayTimeDropdownItems, setFlightDelayTimeDropdownItems] = useState<IFlightDelayTimeDropdownItems[] | null>(null);
  const [flightDelayFilter, setFlightDelayFilter] = useState<IFlightDelayFilter>({ appliedDate: "", date: "", reg: "", delayTime: 0, flightNumber: "" });
  const [delayFlightListFiltered, setDelayFlightListFiltered] = useState<IDelayFlightSearchResult[] | null>(null);
  const [searchIsloading, setSearchIsLoading] = useState<boolean>(false);
  const [delayTimeItemsLoading, setDelayTimeItemsLoading] = useState<boolean>(true);
  const [searchError, setSearchError] = useState<string>("");
  const [delayTimeItemsError, setDelayTimeItemsError] = useState<string>("");

  useEffect(() => {
    if (delayFlightList != null)
      applyFilters(delayFlightList);
  }, [delayFlightList])

  const getFlightDelayTimeDropdownItems = () => {
    setDelayTimeItemsLoading(true);
    fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}DelayTime`, {
      headers: {
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      method: "GET",
    })
      .then(res => {
        if (!res.ok) {
          throw new Error('Something went wrong! Please try again');
        }
        return res.json()
      })
      .then(data => {
        setFlightDelayTimeDropdownItems(data);
        setDelayTimeItemsLoading(false);
        setDelayTimeItemsError("");
      })
      .catch((error) => {
        setDelayTimeItemsLoading(false);
        setDelayTimeItemsError("Something went Wrong !");
      });
  }

  const getFlights = () => {
    if (flightDelayFilter.appliedDate != flightDelayFilter.date) {
      setSearchIsLoading(true);
      fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}flightdelay?` + new URLSearchParams({
        date: flightDelayFilter.date
      }), {
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        method: "GET",
      })
        .then(res => {
          if (!res.ok) {
            throw new Error('Something went wrong! Please try again');
          }
          return res.json()
        })
        .then(data => {
          setDelayFlightList(data);
          setFlightDelayFilter((prevState) => ({ ...prevState, appliedDate: prevState.date }));
          setSearchIsLoading(false);
          setSearchError("");
        })
        .catch((error) => {
          setSearchIsLoading(false);
          setSearchError("Something went Wrong !");
        });
    }
    else {
      applyFilters(delayFlightList || []);
    }
  }

  const applyFilters = (data: IDelayFlightSearchResult[]) => {
    setDelayFlightListFiltered(data.filter((e: IDelayFlightSearchResult) => {
      let filter = true;
      if (flightDelayFilter.reg)
        filter = filter && e.aircraft == flightDelayFilter.reg
      if (flightDelayFilter.delayTime)
        filter = filter && e.delayTime >= flightDelayFilter.delayTime
      if (flightDelayFilter.flightNumber)
        filter = filter && e.flightNumber.trim().indexOf(flightDelayFilter.flightNumber.trim()) != -1
      return filter;
    }));
  }

  const resetFilters = () => {
    setFlightDelayFilter((prevState) => ({ ...prevState, date: "", reg: "", delayTime: 0, flightNumber: "" }));
  }

  return (
    <AfdnContext.Provider
      value={{
        delayFlightList,
        setDelayFlightList,
        flightDelayTimeDropdownItems,
        flightDelayFilter,
        setFlightDelayFilter,
        delayFlightListFiltered,
        searchIsloading,
        delayTimeItemsLoading,
        searchError,
        delayTimeItemsError,
        getFlightDelayTimeDropdownItems,
        getFlights,
        resetFilters,
      }}
    >
      {props.children}
    </AfdnContext.Provider>
  );
};

export default AfdnContext;
