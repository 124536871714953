import './styles.css';
import { faFileText } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLocation from '../PageLocation';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import ApprContext from '../../ApprStore/appr-context';

interface IProps {
  readOnly?: boolean;
}

const ApprAdmin = (props: IProps) => {

  const apprContext = useContext(ApprContext);

  return (
    <>
      <div className="container-fluid  d-flex justify-content-center filter mt-4">
        <div className="col p-3 ps-2 pt-2 pb-2">
          <div className="row">
            <div className="col-sm-2">
              <label className="col-form-label">Departure Date: <span className="danger">*</span></label>
            </div>
            <div className="col-sm-2 mb-1">
              <input type="date" value={apprContext.dateInput ? apprContext.dateInput : ''} className="form-control" onChange={(e) => apprContext.setDateInput(e.target.value)} />
            </div>
            <div className="col-sm-8">
              <button disabled={!apprContext.dateInput || apprContext.apprFlightsLoading} onClick={apprContext.findFlights} className="btn custom-button">
                {apprContext.apprFlightsLoading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                {!apprContext.apprFlightsLoading && <span>Search</span>}
              </button>
            </div>
          </div>
        </div>
      </div>



      <div className="container-fluid mt-4">
        <div className="row g-0 d-flex justify-content-center table">
          <div className="col p-0">
            {apprContext.flights && !apprContext.flights.length && apprContext.dateInput && !apprContext.apprFlightsApiError &&
              <div className="alert alert-warning">No flight found</div>}
            {apprContext.apprFlightsApiError && <div className="alert alert-danger">{apprContext.apprFlightsApiError}</div>}

            {apprContext.flights && apprContext.flights.length != 0 && !apprContext.apprFlightsApiError &&
              <div className="table-responsive">
                <table className="table table-striped table-sm m-0 p-0">
                  <thead className=" custom-color-head">
                    <tr>
                      <th scope="col">Flight Number	</th>
                      <th scope="col">Flight Type	</th>
                      <th scope="col">Delay Time	</th>
                      <th scope="col">Voucher</th>
                      <th scope="col">Monetary Compensation	</th>
                      <th scope="col">Status</th>
                      <th scope="col">Review Flight </th>
                    </tr>
                  </thead>
                  <tbody>
                    {apprContext.flights.map((flight, index) =>
                      <tr key={index}>
                        <td>{flight.FlightNumber} &nbsp; &nbsp; {flight.Dep}-{flight.Arr}</td>
                        <td>{flight.FlightType}</td>
                        <td>{flight.DelayTime}</td>
                        <td>{flight.VoucherAmount}</td>
                        <td>{flight.MCAmount}</td>
                        <td>{flight.Status}</td>
                        {props.readOnly ?
                          <td><Link className="h6" to={`/appradmincreatereadonly/${flight.FlightDateLocal}/${flight.Dep}/${flight.Arr}/${flight.FlightNumber}/${flight.FormId}`}> <FontAwesomeIcon icon={faFileText} /> </Link></td> :
                          <td><Link className="h6" to={`/appradmincreate/${flight.FlightDateLocal}/${flight.Dep}/${flight.Arr}/${flight.FlightNumber}/${flight.FormId}`}> <FontAwesomeIcon icon={faFileText} /> </Link></td>
                        }
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default ApprAdmin;
