import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import PageLocation from '../PageLocation';
import './styles.css';
import { useOktaAuth } from "@okta/okta-react";
import { IAFDNDelaySynopsisHistory, IAFDNDetails, IAFDNDetailsDelayReason, IAFDNForm, IAFDNHistory, IAFDNVersionNumber, IApprDelayReason, IApprDelayStatusCategory, IApprDelayTime, IApprFlightType, IFlightAssociation, IFlightsOptions, INEWFlightAssociation, IRoutesOptions, ISelectedDropdownTypes } from '../../models';
import { getFormattedDate } from '../../utility/Utility';
import AfdnContext from '../../AfdnStore/afdn-context';

interface IProps {
    readOnly?: boolean;
}


const AfdnForm = (props: IProps) => {

    const { authState, oktaAuth } = useOktaAuth();
    const navigate = useHistory();
    const { aRoute, date } = useParams<{ aRoute: string, date: string }>();
    const [afdnForm, setAfdnForm] = useState<IAFDNForm | null>(null);
    const [afdnFormReference, setAfdnFormReference] = useState<IAFDNForm | null>(null);
    const [showDelaySynopsis, setShowDelaySynopsis] = useState<boolean>(false);
    const [flightTypes, setFlightTypes] = useState<IApprFlightType[] | null>(null);
    const [statusCategories, setStatusCategories] = useState<IApprDelayStatusCategory[] | null>(null);
    const [delayReasons, setDelayReasons] = useState<IApprDelayReason[] | null>(null);
    const [versionNumbers, setVersionNumbers] = useState<IAFDNVersionNumber[] | null>(null);
    const [selectedDropdownTypes, setSelectedDropdownTypes] = useState<ISelectedDropdownTypes[]>([]);
    const [aircraft, setAircraft] = useState<string>("");
    const [afdnFormLoading, setAfdnFormLoading] = useState<boolean>(true);
    const [afdnFormError, setAfdnFormError] = useState<string>("");
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [saveSynopsisLoading, setSaveSynopsisLoading] = useState<boolean>(false);
    const [saveError, setSaveError] = useState<string>("");
    const [saveSynopsisError, setSaveSynopsisError] = useState<string>("");
    const [saveEmailLoading, setSaveEmailLoading] = useState<boolean>(false);
    const [saveEmailError, setSaveEmailError] = useState<string>("");
    const [focusedElementId, setFocusedElementId] = useState<number | null>(null);
    const afdnContext = useContext(AfdnContext);

    const [flightNumbers, setFlightNumbers] = useState<IFlightsOptions[]>([]);
    const [flightRoutes, setFlightRoutes] = useState<IRoutesOptions[]>([]);

    const [newFlightAssociation, setNewFlightAssociation] = useState<INEWFlightAssociation[]>([]);


    const [flightAssociationList, setFlightAssociationList] = useState<IFlightAssociation[]>([]);
    const [TTSMappingLoading, setTTSMappingLoading] = useState<boolean>(false);

    const [flightAssociationLoading, setFlightAssociationLoading] = useState<boolean>(false);
    const [routeAssociationLoading, setRouteAssociationLoading] = useState<boolean>(false);


    useEffect(() => {
        getAfdnForm();
        getFlightTypes();
        getStatusCategories();
        getDelayReasons();
        getVersionNumber();
        getAimsFlightAircraft();
    }, []);

    const initiateNewFlightAssociation = (legId: number) => {
        setNewFlightAssociation(prev => [...prev.filter(e => e.legId != legId), {
            legId: legId,
            flightNumber: "Flight",
            flightDate: "",
            flightRoute: "Flight Route",
            messageEn: "",
            messagefr: "",
        }])
    }

    const onTTSMappingInputChange = async (e: string, legId: number) => {
        const newFlightAssociationElement: INEWFlightAssociation = newFlightAssociation.find(e => e.legId == legId) || {
            legId: legId,
            flightNumber: "Flight",
            flightDate: "",
            flightRoute: "Flight Route",
            messageEn: "",
            messagefr: "",
        };
        const newFlightAssociationElementSet: INEWFlightAssociation = { ...newFlightAssociationElement, flightDate: e, flightNumber: "Flight", flightRoute: "Flight Route" };
        setNewFlightAssociation(prev => [...prev.filter(element => element.legId != legId), newFlightAssociationElementSet]);
        await getFlightNumbers(e, legId);
    }

    const onTTSMappingFlightChange = async (e: string, legId: number) => {
        const newFlightAssociationElement: INEWFlightAssociation = newFlightAssociation.find(e => e.legId == legId) || {
            legId: legId,
            flightNumber: "Flight",
            flightDate: "",
            flightRoute: "Flight Route",
            messageEn: "",
            messagefr: "",
        };
        const newFlightAssociationElementSet: INEWFlightAssociation = { ...newFlightAssociationElement, flightNumber: e, flightRoute: "Flight Route" };
        setNewFlightAssociation(prev => [...prev.filter(element => element.legId != legId), newFlightAssociationElementSet]);
        await getFlightDetails(newFlightAssociationElement.flightDate, e, legId);
    }

    const onTTSMappingRouteChange = async (e: string, legId: number) => {
        const newFlightAssociationElement: INEWFlightAssociation = newFlightAssociation.find(e => e.legId == legId) || {
            legId: legId,
            flightNumber: "Flight",
            flightDate: "",
            flightRoute: "Flight Route",
            messageEn: "",
            messagefr: "",
        };
        const newFlightAssociationElementSet: INEWFlightAssociation = { ...newFlightAssociationElement, flightRoute: e };
        setNewFlightAssociation(prev => [...prev.filter(element => element.legId != legId), newFlightAssociationElementSet]);
    }

    const onTTSMappingMessageEnChange = async (e: string, legId: number) => {
        const newFlightAssociationElement: INEWFlightAssociation = newFlightAssociation.find(e => e.legId == legId) || {
            legId: legId,
            flightNumber: "Flight",
            flightDate: "",
            flightRoute: "Flight Route",
            messageEn: "",
            messagefr: "",
        };
        const newFlightAssociationElementSet: INEWFlightAssociation = { ...newFlightAssociationElement, messageEn: e };
        setNewFlightAssociation(prev => [...prev.filter(element => element.legId != legId), newFlightAssociationElementSet]);
    }


    const onTTSMappingMessageFrChange = async (e: string, legId: number) => {
        const newFlightAssociationElement: INEWFlightAssociation = newFlightAssociation.find(e => e.legId == legId) || {
            legId: legId,
            flightNumber: "Flight",
            flightDate: "",
            flightRoute: "Flight Route",
            messageEn: "",
            messagefr: "",
        };
        const newFlightAssociationElementSet: INEWFlightAssociation = { ...newFlightAssociationElement, messagefr: e };
        setNewFlightAssociation(prev => [...prev.filter(element => element.legId != legId), newFlightAssociationElementSet]);
    }


    const setAfdnDetailsProperty = (element: IAFDNDetails, propertyName: string, newValue: any) => {
        setAfdnForm((prevState) => prevState ?
            {
                ...prevState,
                details: [...prevState.details.map(e => {
                    if (e.id == element.id) {
                        return { ...e, [propertyName]: newValue };
                    }
                    return e;
                })]
            }
            : null);
    }

    const addDelayReason = (element: IAFDNDetails, newDelayReason: IAFDNDetailsDelayReason, index: number) => {
        if (newDelayReason.delayReasonCode) {
            setAfdnForm((prevState) => prevState ?
                {
                    ...prevState,
                    details: [...prevState.details.map(e => {
                        if (e.id == element.id) {
                            return { ...e, delayReason: [...element.delayReason, newDelayReason].sort((a, b) => a.delayReasonCode.localeCompare(b.delayReasonCode)) };
                        }
                        return e;
                    })]
                }
                : null);
            setSelectedDropdownTypes((prevState) => [...prevState.filter(e => e.index != index), { index: index, selectedFlightType: 0, selectedStatusCategory: 0 }]);

        }
    }


    const removeDelayReason = (element: IAFDNDetails, delayReasonCode: string) => {
        setAfdnForm((prevState) => prevState ?
            {
                ...prevState,
                details: [...prevState.details.map(e => {
                    if (e.id == element.id) {
                        return { ...e, delayReason: element.delayReason.filter(e => e.delayReasonCode != delayReasonCode) }
                    }
                    return e;
                })]
            }
            : null);
    }

    const setDelayReasonPrimary = (element: IAFDNDetails, delayReasonCode: string) => {
        setAfdnForm((prevState) => prevState ?
            {
                ...prevState,
                details: [...prevState.details.map(e => {
                    if (e.id == element.id) {
                        return { ...e, delayReason: element.delayReason.map(el => (el.delayReasonCode == delayReasonCode ? { ...el, primaryReason: true } : { ...el, primaryReason: false })) };
                    }
                    return e;
                })]
            }
            : null);
    }

    const getAimsFlightAircraft = () => {
        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}aimsflights/aircraft/${aRoute}/${date}`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json()
            })
            .then(data => {
                setAircraft(data.aircraft);
            })
            .catch((error) => {
            });
    }

    const getFlightTypes = () => {
        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}types`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json()
            })
            .then(data => {
                setFlightTypes(data);
            })
            .catch((error) => {

            });
    }
    const getStatusCategories = () => {
        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}statusCategories`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json()
            })
            .then(data => {
                setStatusCategories(data);
            })
            .catch((error) => {

            });
    }
    const getDelayReasons = () => {
        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}delayReasons`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json()
            })
            .then(data => {
                setDelayReasons(data);
            })
            .catch((error) => {

            });
    }

    const getVersionNumber = () => {
        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}VersionNumber`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json()
            })
            .then(data => {
                setVersionNumbers(data);
            })
            .catch((error) => {

            });
    }

    const getAfdnForm = async () => {
        setAfdnFormLoading(true);
        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}flightdelay/${aRoute}/${date}`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json()
            })
            .then(async (data) => {
                setAfdnFormLoading(false);
                setAfdnFormError("");
                setAfdnForm(data);
                setAfdnFormReference(data);
                setSelectedDropdownTypes(data.details.map((e: IAFDNDetails, i: number) => ({ index: i, selectedFlightType: 0, selectedStatusCategory: 0 })));
                setFlightAssociationList([]);
                for (const element of data.details) {
                    await getFlightAssociations(element.flightNumber, element.flightDateOrg, element.flightDep, element.flightArr);
                    initiateNewFlightAssociation(element.id);
                }
            })
            .catch((error) => {
                setAfdnFormLoading(false);
                setAfdnFormError("Something went wrong !");
            });
    }

    const newAfdnHistory = (type: string): IAFDNHistory => {
        let newAfdnHistory: IAFDNHistory = {
            afdnHeaderId: afdnForm?.header.id || 0,
            issuedDate: new Date(),
            issuedBy: authState?.idToken?.claims['email'] || "",
            processing: type,
            afdnVersionNumber: afdnForm?.afdnHistory[afdnForm?.afdnHistory.length - 1]?.afdnVersionNumber || 1,
            emailLink: "",
        };

        if (afdnForm?.afdnHistory && type == "Save & Email")
            newAfdnHistory.afdnVersionNumber = afdnForm?.afdnHistory[afdnForm?.afdnHistory.length - 1]?.afdnVersionNumber + 1 || 2;

        return newAfdnHistory;
    }

    const saveAfdnForm = () => {
        setSaveLoading(true);
        let newAfdnForm: IAFDNForm | null = null;
        if (afdnForm)
            newAfdnForm = { ...afdnForm, afdnHistory: [newAfdnHistory("Save")] };

        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}flightdelay?` + new URLSearchParams({
            email: 'false',
        }), {
            method: 'PUT',
            body: JSON.stringify(newAfdnForm),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`
            },
        }).then(res => {
            if (!res.ok) {
                throw new Error('Something went wrong! Please try again');
            }
            return res.json()
        })
            .then(async (data) => {
                setSaveLoading(false);
                setSaveError("");
                setSaveEmailError("");
                setAfdnFormReference(data);
                setAfdnForm(data);
                for (const element of data.details) {
                    initiateNewFlightAssociation(element.id);
                }
            })
            .catch((error) => {
                setSaveLoading(false);
                setSaveError("Something went wrong!");
            });;
    }

    const saveAndEmailAfdnForm = () => {
        setSaveEmailLoading(true);
        let newAfdnForm: IAFDNForm | null = null;
        if (afdnForm)
            newAfdnForm = { ...afdnForm, afdnHistory: [newAfdnHistory("Save & Email")] };

        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}flightdelay?` + new URLSearchParams({
            email: 'true',
        }), {
            method: 'PUT',
            body: JSON.stringify(newAfdnForm),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`
            },
        }).then(res => {
            if (!res.ok) {
                throw new Error('Something went wrong! Please try again');
            }
            return res.json()
        })
            .then(async (data) => {
                setSaveEmailLoading(false);
                setSaveEmailError("");
                setSaveError("");
                setAfdnFormReference(data);
                setAfdnForm(data);
                for (const element of data.details) {
                    initiateNewFlightAssociation(element.id);
                }

                afdnContext.setDelayFlightList((prevState) => prevState ?
                    [...prevState.map(e => {
                        if (e.aroute == afdnForm?.header.aroute && e.departureDate == afdnForm?.header.flightDepartureDateInt) {
                            return { ...e, status: versionNumbers?.filter(e => e.id == data.afdnHistory[data.afdnHistory.length - 1]?.afdnVersionNumber)[0]?.description || "New" };
                        }
                        return e;
                    })]
                    : null);
            })
            .catch((error) => {
                setSaveEmailLoading(false);
                setSaveEmailError("Something went wrong!");
            });;
    }

    const resetForm = () => {
        setAfdnForm(afdnFormReference);
    }


    const afdnFormHasChanged = (): boolean => {

        let details = afdnForm?.details.map(e => ({ ...e, tarmacDelay: null, delayStatus: null, delaySynopsis: null, }));
        let detailsReference = afdnFormReference?.details.map(e => ({ ...e, tarmacDelay: null, delayStatus: null, delaySynopsis: null, }));
        return JSON.stringify(details) !== JSON.stringify(detailsReference) || afdnForm?.header.delayDescription != afdnFormReference?.header.delayDescription;
    }

    const afdnFormDetailsHasPrimaryReason = (): boolean => {
        let hasPrimary = false;
        afdnForm?.details.forEach(e => {
            if (e.delayReason.filter(el => el.primaryReason).length > 0)
                hasPrimary = true;
        });

        return hasPrimary;
    }

    const afdnFormDetailsElementHasPrimaryReason = (id: number): boolean => {
        let hasPrimary = false;
        let detailsReference = afdnFormReference?.details.filter(e => e.id == id)[0];
        if (detailsReference && detailsReference.delayReason.filter((el: IAFDNDetailsDelayReason) => el.primaryReason).length > 0)
            hasPrimary = true;
        return hasPrimary;
    }


    const notifyUncheckedWhenNoPrimaryReason = (): boolean => {
        let unchecked = true;
        afdnForm?.details.forEach(e => {
            if (e.delayReason.filter(el => el.primaryReason).length <= 0 && e.notify)
                unchecked = false;
        });

        return unchecked;
    }

    const notifyChecked = (): boolean => {
        let checked = false;
        afdnForm?.details.forEach(e => {
            if (e.notify)
                checked = true;
        });

        return checked;
    }




    const canAddAfdnDelaySynopsis = (): boolean => {

        let versionNumber = afdnForm?.afdnHistory[afdnForm?.afdnHistory.length - 1]?.afdnVersionNumber || 1;
        return versionNumber > 1;
    }

    const delaySynopsisHasChanged = (element: IAFDNDetails): boolean => {

        let elementRef = afdnFormReference?.details.filter(e => e.id === element.id)[0];
        if (elementRef)
            return element.tarmacDelay != elementRef.tarmacDelay || element.delayStatus != elementRef.delayStatus || element.delaySynopsis != elementRef.delaySynopsis;
        else
            return false
    }

    const delaySynopsisEmpty = (element: IAFDNDetails): boolean => {

        return element.tarmacDelay == null || !element.delayStatus || !element.delaySynopsis;
    }

    const newAfdnDelaySynopsisHistory = (element: IAFDNDetails): IAFDNDelaySynopsisHistory => {
        let newAfdnDelaySynopsisHistory: IAFDNDelaySynopsisHistory = {
            afdnHeaderId: afdnForm?.header.id || 0,
            afdnDetailsId: element.id || 0,
            issuedDate: new Date(),
            issuedBy: authState?.idToken?.claims['email'] || "",
            processed: element.delayStatus,
            emailLink: "",
        };
        return newAfdnDelaySynopsisHistory;
    }

    const saveDelaySynopsis = (element: IAFDNDetails) => {
        setSaveSynopsisLoading(true);
        let newAfdnForm: IAFDNForm | null = null;
        if (afdnForm) {
            newAfdnForm = { ...afdnForm, details: [element], afdnDelaySynopsisHistory: [newAfdnDelaySynopsisHistory(element)] };
        }

        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}flightdelay/updateSynopsis`, {
            method: 'PATCH',
            body: JSON.stringify(newAfdnForm),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`
            },
        }).then(res => {
            if (!res.ok) {
                throw new Error('Something went wrong! Please try again');
            }
            return res.json()
        })
            .then(async (data) => {
                setSaveSynopsisLoading(false);
                setSaveSynopsisError("");
                setAfdnFormReference(data);
                setAfdnForm(data);
                afdnContext.setDelayFlightList((prevState) => prevState ?
                    [...prevState.map(e => {
                        if (e.aroute == afdnForm?.header.aroute && e.departureDate == afdnForm?.header.flightDepartureDateInt) {
                            return { ...e, synopsisStatus: data?.afdnDelaySynopsisHistory[data?.afdnDelaySynopsisHistory.length - 1]?.processed || "" };
                        }
                        return e;
                    })]
                    : null);
            })
            .catch((error) => {
                setSaveSynopsisLoading(false);
                setSaveSynopsisError("Something went wrong!");
            });;
    }

    const setFocusedDelaySynopsis = (elementId: number) => {
        elementId == focusedElementId ? setShowDelaySynopsis(!showDelaySynopsis) : setShowDelaySynopsis(showDelaySynopsis);
        setFocusedElementId(elementId);
    }

    const getFlightNumbers = async (depFromDate: string, legId: number) => {
        setFlightAssociationLoading(true);
        setRouteAssociationLoading(true);
        const apiFlightNumberEnd = `${process.env.REACT_APP_AIR_PRC_API_URL}flights/FlightNumbers?date=${depFromDate}`
        await fetch(apiFlightNumberEnd, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject();
                }
                return response.json();
            })
            .then((data) => {
                setFlightNumbers(prev => [...prev.filter(element => element.legId != legId), { legId: legId, flights: data }]);
                setFlightAssociationLoading(false);
                setRouteAssociationLoading(false);
            })
            .catch(err => {
                console.log("Error:", err);
                setFlightAssociationLoading(false);
                setRouteAssociationLoading(false);
            });


    };

    const getFlightDetails = (depFromDate: string | null | undefined, flightNum: string, legId: number) => {
        const apiFlightDetailEnd = `${process.env.REACT_APP_AIR_PRC_API_URL}flightStatus?date=${depFromDate}&flightno=${flightNum}`;
        setRouteAssociationLoading(true);
        fetch(
            `${process.env.REACT_APP_AIR_PRC_API_URL}flightStatus?date=${depFromDate}&flightno=${flightNum}`,
            {
                headers: {
                    Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
                },
                method: "GET",
            }
        )
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Something went wrong! Please try again");
                }
                return res.json();
            })
            .then((data) => {
                const routes = data?.map((fly: { gateway: string; destination: string; }) => fly.gateway + "-" + fly.destination);
                setFlightRoutes(prev => [...prev.filter(element => element.legId != legId), { legId: legId, routes: routes }]);
                setRouteAssociationLoading(false);


            })
            .catch((error) => {
                setRouteAssociationLoading(false);
            });
    };


    const saveTTSMapping = (element: IAFDNDetails) => {

        setTTSMappingLoading(true);
        const newFlightAssociationElement: INEWFlightAssociation = newFlightAssociation.find(e => e.legId == element.id) || {
            legId: element.id,
            flightNumber: "Flight",
            flightDate: "",
            flightRoute: "Flight Route",
            messageEn: "",
            messagefr: "",
        };

        const flighAssociation: IFlightAssociation = {
            id: 0,
            legFlightNumber: element.flightNumber,
            legFlightDate: element.flightDateOrg,
            legOrigin: element.flightDep,
            legDestination: element.flightArr,
            flightNumber: newFlightAssociationElement.flightNumber,
            flightDate: newFlightAssociationElement.flightDate,
            origin: newFlightAssociationElement.flightRoute.split("-")[0],
            destination: newFlightAssociationElement.flightRoute.split("-")[1],
            message: {
                en: newFlightAssociationElement.messageEn,
                fr: newFlightAssociationElement.messagefr,
            },
        }
        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}flightAssociations`, {
            method: 'POST',
            body: JSON.stringify(flighAssociation),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`
            },
        }).then(res => {
            if (!res.ok) {
                throw new Error('Something went wrong! Please try again');
            }
            return res.json()
        })
            .then(async (data) => {
                setFlightAssociationList(prevState => [...prevState, { ...flighAssociation, id: data.id }])
                setTTSMappingLoading(false);
                initiateNewFlightAssociation(element.id);
            })
            .catch((error) => {
                setTTSMappingLoading(false);
            });;
    }

    const saveTTSMappingDisabled = (id: number) => {
        const newFlightAssociationElement: INEWFlightAssociation = newFlightAssociation.find(e => e.legId == id) || {
            legId: id,
            flightNumber: "Flight",
            flightDate: "",
            flightRoute: "Flight Route",
            messageEn: "",
            messagefr: "",
        };

        let flightAssociationElement = null;


        //Check if leg is added    
        const legElement = afdnForm?.details?.find(element => element.id == id);
        if (legElement) {
            const flighAssociationForLeg = flightAssociationList.filter(flt => flt.legFlightNumber == legElement.flightNumber && flt.legFlightDate == legElement.flightDateOrg && flt.legOrigin == legElement.flightDep && flt.legDestination == legElement.flightArr);
            flightAssociationElement = flighAssociationForLeg.find((e: IFlightAssociation) => e.flightNumber == newFlightAssociationElement.flightNumber
            && e.flightDate.split("T")[0] == newFlightAssociationElement.flightDate
            && e.origin == newFlightAssociationElement.flightRoute.split("-")[0]
            && e.destination == newFlightAssociationElement.flightRoute.split("-")[1]);
        }


        if (newFlightAssociationElement.flightNumber == "Flight" ||
            newFlightAssociationElement.flightDate == "" ||
            newFlightAssociationElement.flightRoute == "Flight Route" ||
            flightAssociationElement) {
            return true;
        }
        else {
            return false;
        }

    }

    const getFlightAssociations = async (legFlightNumber: string, legFlightDate: string, legOrigin: string, legDestination: string) => {
        const response = await fetch(
            `${process.env.REACT_APP_AIR_PRC_API_URL}flightAssociations?legFlightNumber=${legFlightNumber}&legFlightDate=${legFlightDate}
            &legOrigin=${legOrigin}&legDestination=${legDestination}`,
            {
                headers: {
                    Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
                },
                method: "GET",
            }
        );
        const flightAssications = await response.json();
        if (flightAssications.length) {
            setFlightAssociationList(prevState => [...prevState, ...flightAssications])
        }

    }

    const deleteFlightAssociation = async (fltId: number) => {
        await fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}flightAssociations/${fltId}`
            , {
                headers: {
                    Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
                },
                method: "DELETE",
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject();
                }
                setFlightAssociationList(prev => prev.filter(e => e.id != fltId))
            })



    };


    return (
        <>
            {afdnFormLoading && <div className="container-fluid">
                <div className="row g-0 d-flex justify-content-center">
                    <div className="spinner-border m-5" role="status"> </div>
                </div>
            </div>}

            {!afdnFormError && !afdnFormLoading && <div className="container-fluid content mt-4">
                <div className="row g-0 d-flex justify-content-center">
                    <div className="col">
                        <div className="d-flex flex-wrap me-4">
                            <div className="me-4"><strong>Issued time: </strong><span>{new Date().toLocaleString()}</span></div>
                            <div className="me-4"><strong>Issued By: </strong> <span>{authState?.idToken?.claims['email'] || ""}</span></div>
                            <div className="me-4"><strong>Aircraft Reg: </strong> <span> {aircraft}</span></div>
                            <div className="me-4"><strong>Status: </strong> <span> {versionNumbers?.filter(e => e.id == afdnForm?.afdnHistory[afdnForm?.afdnHistory.length - 1]?.afdnVersionNumber)[0]?.description || "New"}</span></div>
                        </div>
                        {!props.readOnly && <div><strong>Delay Instructions For Handler: </strong></div>}
                        <div className="container-fluid cont m-0 p-0">

                            {!props.readOnly &&
                                <textarea readOnly className="form-control" rows={7}
                                    style={{ fontFamily: "Courier", fontSize: "12px", fontWeight: "bold", backgroundColor: "rgb(209, 207, 207)" }}
                                    value="- Ensure all concerned at your station are advised of this delayed flight.
                                - Ensure passengers are aware of the delay reason and announcements are made in compliance to Air Passenger Protection Regulations (APPR).
                                - Initiate passenger delay disposition report (PDDR).
                                - Advise local airport and/or government authorities of this delay.
                                - Reschedule destination transfer as required.
                                - Contact OCC department for any further details."
                                />}

                            <div className="col p-0 mt-2">
                                <p className="h6">Original Schedule Times: </p>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped table-sm">
                                    <thead className=" custom-color-head custom-color-head">
                                        <tr>
                                            <th scope="col">Flight # </th>
                                            <th scope="col">Origin</th>
                                            <th scope="col">STD (in Local) </th>
                                            <th scope="col">Destination</th>
                                            <th scope="col">STA (in Local) </th>
                                            <th scope="col">Date (in Local)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {afdnForm && afdnForm.details.map((element, index) =>
                                            <tr key={index}>
                                                <td>{element.flightNumber}</td>
                                                <td>{element.flightDep}</td>
                                                <td>{element.flightSTD}</td>
                                                <td>{element.flightArr}</td>
                                                <td>{element.flightSTA}</td>
                                                <td>{getFormattedDate(new Date(element.flightDateOrg))}</td>
                                            </tr>)}
                                    </tbody>
                                </table>
                            </div>

                            <div className="col p-0 mt-2">
                                <p className="h6">Delayed Schedule Times: </p>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped table-sm">
                                    <thead className=" custom-color-head custom-color-head">
                                        <tr>
                                            <th scope="col">Flight # </th>
                                            <th scope="col">Origin</th>
                                            <th scope="col">ETD (in Local)	 </th>
                                            <th scope="col">Departure Delay	</th>
                                            <th scope="col">Destination </th>
                                            <th scope="col">ETA (in Local)	</th>
                                            <th scope="col">Arrive Delay		</th>
                                            <th scope="col">Date (in Local)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {afdnForm && afdnForm.details.map((element, index) =>
                                            <tr key={index}>
                                                <td>{element.flightNumber}</td>
                                                <td>{element.flightDep}</td>
                                                <td>{element.flightETD}</td>
                                                <td>{element.flightDepDelay}</td>
                                                <td>{element.flightArr}</td>
                                                <td>{element.flightETA}</td>
                                                <td>{element.flightArrDelay}</td>
                                                <td>{getFormattedDate(new Date(element.flightDate))}</td>
                                            </tr>)}
                                    </tbody>
                                </table>

                                {!props.readOnly &&
                                    <>
                                        <div className="col p-0 mt-2">
                                            <p className="h6"> Description of Delay: </p>
                                        </div>
                                        <textarea
                                            value={afdnForm?.header.delayDescription}
                                            onChange={(e) => setAfdnForm((prevState) => prevState ? ({ ...prevState, header: { ...prevState.header, delayDescription: e.target.value } }) : null)}
                                            className="form-control"
                                            rows={3}
                                            style={{ fontFamily: "Courier", fontSize: "12px", fontWeight: "bold" }}
                                        />
                                    </>}
                            </div>
                            {afdnForm && afdnForm.details.map((element, index) =>

                                <div key={index} className="col p-0 mt-4 afdnform">

                                    <div className="row">
                                        <div className="col-sm-2">
                                            <label className="col-form-label"><strong> Station: {element.flightDep} </strong> </label>
                                        </div>
                                        <div className="form-check col-sm-2 ps-5 mt-1">
                                            <input
                                                checked={element.notify}
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => setAfdnDetailsProperty(element, "notify", !element.notify)}
                                                disabled={props.readOnly}
                                            />
                                            <label className="form-check-label">
                                                <strong>Notify</strong>
                                            </label>
                                        </div>
                                        <div className="col-sm-2">
                                            <label className="col-form-label">{element.flightNumber} {element.flightDep}-{element.flightArr} </label>
                                        </div>
                                    </div>


                                    {!props.readOnly && <div className="row">
                                        <div className="col-sm-4">
                                            <label className="col-form-label"><strong>Flight Type:</strong></label>
                                        </div>
                                        <div className="col-sm-4">
                                            <select
                                                className="form-select form-select-sm"
                                                value={selectedDropdownTypes.filter(e => e.index == index)[0].selectedFlightType || 0}
                                                onChange={(event) => setSelectedDropdownTypes((prevState) => [...prevState.filter(e => e.index != index), { index: index, selectedFlightType: + event.target.value, selectedStatusCategory: prevState.filter(e => e.index == index)[0].selectedStatusCategory }])}
                                            >
                                                <option value={0}>--Please select</option>
                                                {flightTypes && flightTypes.map((element, index) =>
                                                    <option key={index} value={element.id || ''}>{element.description}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>}

                                    {!props.readOnly && <div className="row">
                                        <div className="col-sm-4">
                                            <label className="col-form-label"><strong>Flight Delay Status Category:</strong></label>
                                        </div>
                                        <div className="col-sm-4">
                                            <select
                                                disabled={!selectedDropdownTypes.filter(e => e.index == index)[0].selectedFlightType}
                                                className="form-select form-select-sm"
                                                value={selectedDropdownTypes.filter(e => e.index == index)[0].selectedStatusCategory || 0}
                                                onChange={(event) => setSelectedDropdownTypes((prevState) => [...prevState.filter(e => e.index != index), { index: index, selectedFlightType: prevState.filter(e => e.index == index)[0].selectedFlightType, selectedStatusCategory: + event.target.value }])}
                                            >
                                                <option value={0}>--Please select</option>
                                                {statusCategories && statusCategories.filter(e => e.apprFlightTypeId == selectedDropdownTypes.filter(e => e.index == index)[0].selectedFlightType).map((element, index) =>
                                                    <option key={index} value={element.id || ''}>{element.description}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>}

                                    {!props.readOnly && <div className="row">
                                        <div className="col-sm-4">
                                            <label className="col-form-label"><strong>Flight Delay Reason: </strong></label>
                                        </div>
                                        <div className="col-sm-4">
                                            <select
                                                disabled={!selectedDropdownTypes.filter(e => e.index == index)[0].selectedStatusCategory}
                                                className="form-select form-select-sm"
                                                onChange={(e) => addDelayReason(element, { afdnDetailsId: element.id, delayReasonCode: e.target.value, primaryReason: false }, index)}
                                            >
                                                <option value={''}>--Please select</option>
                                                {delayReasons && delayReasons.filter(e => e.fdscId == selectedDropdownTypes.filter(e => e.index == index)[0].selectedStatusCategory && !element.delayReason.map(el => el.delayReasonCode).includes(e.code)).map((element, index) =>
                                                    <option key={index} value={element.code || ''}>{element.code}- {element.reasonEn}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>}

                                    {element.delayReason.length > 0 && <div className="row mt-1">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-sm">
                                                <thead className=" custom-color-head custom-color-head">
                                                    <tr>
                                                        <th scope="col">Code </th>
                                                        <th scope="col">Customer Announcement </th>
                                                        <th scope="col">Primary Reason? </th>
                                                        <th scope="col">Delete </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {element.delayReason.map((e, i) =>
                                                        <tr key={i}>
                                                            <td>{e.delayReasonCode}</td>
                                                            <td>{delayReasons?.filter(el => el.code == e.delayReasonCode)[0]?.reasonEn} </td>
                                                            <td> <input
                                                                type="radio"
                                                                name={`primary_reason${index}`}
                                                                onChange={(event) => { setDelayReasonPrimary(element, e.delayReasonCode) }}
                                                                checked={e.primaryReason}
                                                            />
                                                            </td>
                                                            <td><FontAwesomeIcon onClick={() => removeDelayReason(element, e.delayReasonCode)} icon={faTrash} /> </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}

                                    <div className="row g-0">
                                        <div className="col-sm-3">
                                            <label className="col-form-label"><strong>Allowances for meal vouchers
                                                @</strong></label>
                                        </div>
                                        <div className="col-sm-2">
                                            <select
                                                className="form-select form-select-sm"
                                                value={element.mealVoucherAmount || ""}
                                                onChange={(event) => setAfdnDetailsProperty(element, "mealVoucherAmount", event.target.value)}
                                                disabled={props.readOnly}
                                            >
                                                <option value={''}>Select Amount</option>
                                                {['5.00', '10.00', '15.00', '20.00', '25.00', '30.00', '35.00', '40.00', '45.00', '50.00', '55.00', '60.00', '70.00', '75.00', '80.00', '85.00', '90.00', '95.00', '100.00'].map((element, index) =>
                                                    <option key={index} value={element || ''}>{element}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-sm-1">
                                            <select
                                                className="form-select form-select-sm"
                                                value={element.mealVoucherCurrency || ""}
                                                onChange={(event) => setAfdnDetailsProperty(element, "mealVoucherCurrency", event.target.value)}
                                                disabled={props.readOnly}
                                            >
                                                {['CAD', 'USD'].map((element, index) =>
                                                    <option key={index} value={element || ''}>{element}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="col-form-label"><strong> per passenger to be issued:</strong></label>
                                        </div>
                                        <div className="col-sm-3 ps-2">
                                            {["NIL", "ALL", "AIR ONLY"].map((e, i) =>
                                                <div key={i} className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        value={e}
                                                        checked={e == element.mealVoucherOption}
                                                        type="radio"
                                                        name={`mealVoucherOption${index}`}
                                                        onChange={(event) => setAfdnDetailsProperty(element, "mealVoucherOption", event.target.value)}
                                                        disabled={props.readOnly}
                                                    />
                                                    <label className="form-check-label">
                                                        {e}
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row g-0">
                                        <div className="col-sm-10">
                                            <label className="col-form-label"><strong>Or taxi to residents and return to airport to
                                                be issued:</strong></label>
                                        </div>
                                        <div className="col-sm-2">
                                            {["NO", "YES"].map((e, i) =>
                                                <div key={i} className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        value={e}
                                                        checked={e == element.orTaxi}
                                                        type="radio"
                                                        name={`ortaxi${index}`}
                                                        onChange={(event) => setAfdnDetailsProperty(element, "orTaxi", event.target.value)}
                                                        disabled={props.readOnly}
                                                    />
                                                    <label className="form-check-label">
                                                        {e}
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="row g-0">
                                        <div className="col-sm-10">
                                            <label className="col-form-label"><strong>Please arrange Hotel accommodations (day room
                                                or overnight stay as required) for out of town passengers:</strong></label>
                                        </div>
                                        <div className="col-sm-2">
                                            {["NO", "YES"].map((e, i) =>
                                                <div key={i} className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        value={e}
                                                        checked={e == element.hotelAcc}
                                                        type="radio"
                                                        name={`hotelAcc${index}`}
                                                        onChange={(event) => setAfdnDetailsProperty(element, "hotelAcc", event.target.value)}
                                                        disabled={props.readOnly}
                                                    />
                                                    <label className="form-check-label">
                                                        {e}
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="row g-0">
                                        <div className="col-sm-10">
                                            <label className="col-form-label"><strong>Please extend hotel check-outs for passengers
                                                (including overnight accommodation if required) in Destination: </strong></label>
                                        </div>
                                        <div className="col-sm-2">
                                            {["NO", "YES"].map((e, i) =>
                                                <div key={i} className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        value={e}
                                                        checked={e == element.hotelCheckout}
                                                        type="radio"
                                                        name={`hotelCheckout${index}`}
                                                        onChange={(event) => setAfdnDetailsProperty(element, "hotelCheckout", event.target.value)}
                                                        disabled={props.readOnly}
                                                    />
                                                    <label className="form-check-label">
                                                        {e}
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="row g-0">
                                        <div className="col-sm-12">
                                            <label className="col-form-label"><strong>Passenger Welfare:</strong></label>
                                        </div>
                                        <div className="col-sm-11">
                                            <textarea className="form-control"
                                                rows={3}
                                                style={{ fontFamily: "Courier", fontSize: "12px", fontWeight: "bold" }}
                                                value={element.paxWelfare}
                                                onChange={(e) => setAfdnDetailsProperty(element, "paxWelfare", e.target.value)}
                                                disabled={props.readOnly}
                                            />
                                        </div>
                                    </div>

                                    {!props.readOnly && <div className="row g-0">
                                        <div className="col-sm-12">
                                            <label className="col-form-label"><strong>APPR Comments: </strong></label>
                                        </div>
                                        <div className="col-sm-11">
                                            <textarea
                                                className="form-control"
                                                rows={3}
                                                style={{ fontFamily: "Courier", fontSize: "12px", fontWeight: "bold" }}
                                                value={element.apprComments}
                                                onChange={(e) => setAfdnDetailsProperty(element, "apprComments", e.target.value)}
                                            />
                                        </div>
                                    </div>}

                                    {!props.readOnly && <div className="row g-2">
                                        <div className="col-sm-12">
                                            <label className="col-form-label"><strong>TTS Mapping: </strong></label>
                                        </div>
                                        <div className="row g-0" style={{ background: "" }}>
                                            <div className="col-sm-3 p-1" >
                                                <label style={{ marginRight: "0.6rem" }}>Departure Date: </label>
                                                <input
                                                    value={newFlightAssociation.find(item => item.legId == element.id)?.flightDate || ''}
                                                    onChange={(e) => onTTSMappingInputChange(e.target.value, element.id)}
                                                    type="date"
                                                    className="form-control form-control-sm"
                                                    placeholder='departure date' />
                                            </div>
                                            <div className="col-sm-3 p-1">
                                                <label style={{ marginRight: "0.6rem" }}>Flight: </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    value={newFlightAssociation.find(item => item.legId == element.id)?.flightNumber}
                                                    onChange={(e) => onTTSMappingFlightChange(e.target.value, element.id)}
                                                    disabled={flightAssociationLoading}
                                                >
                                                    <option value="Flight">Flight</option>
                                                    {flightNumbers && flightNumbers !== null &&
                                                        flightNumbers.find(item => item.legId == element.id)?.flights.map((flight) =>
                                                            <option key={flight} value={flight}>{flight}</option>
                                                        )}
                                                </select>
                                            </div>
                                            <div className="col-sm-3 p-1">
                                                <label style={{ marginRight: "0.6rem" }}>Route: </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    value={newFlightAssociation.find(item => item.legId == element.id)?.flightRoute}
                                                    onChange={(e) => onTTSMappingRouteChange(e.target.value, element.id)}
                                                    disabled={routeAssociationLoading}
                                                >
                                                    <option value="Flight Route">Flight Route</option>
                                                    {flightRoutes && flightRoutes !== null &&
                                                        flightRoutes.find(item => item.legId == element.id)?.routes.map((route) =>
                                                            <option key={route} value={route}>{route}</option>
                                                        )}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-sm-5">
                                            <label className="col-form-label">Comment English</label>
                                            <textarea
                                                className="form-control"
                                                rows={3}
                                                style={{ fontFamily: "Courier", fontSize: "12px", fontWeight: "bold" }}
                                                value={newFlightAssociation.find(item => item.legId == element.id)?.messageEn}
                                                onChange={(e) => onTTSMappingMessageEnChange(e.target.value, element.id)}
                                            />
                                        </div>
                                        <div className="col-sm-5">
                                            <label className="col-form-label">Comment French</label>

                                            <textarea
                                                className="form-control"
                                                rows={3}
                                                style={{ fontFamily: "Courier", fontSize: "12px", fontWeight: "bold" }}
                                                value={newFlightAssociation.find(item => item.legId == element.id)?.messagefr}
                                                onChange={(e) => onTTSMappingMessageFrChange(e.target.value, element.id)}
                                            />
                                        </div>
                                        <div className='row'>
                                            <div className="col mt-2 p-1">
                                                <button disabled={saveTTSMappingDisabled(element.id) || TTSMappingLoading} onClick={() => saveTTSMapping(element)} className="btn btn-secondary btn-sm">
                                                    {TTSMappingLoading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                                                    {!TTSMappingLoading && <span>Save Mapping</span>}
                                                </button>
                                            </div>
                                        </div>
                                        {flightAssociationList.filter(flt => flt.legFlightNumber == element.flightNumber && flt.legFlightDate == element.flightDateOrg && flt.legOrigin == element.flightDep && flt.legDestination == element.flightArr).length > 0 && <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-sm">
                                                <thead className=" custom-color-head custom-color-head">
                                                    <tr>
                                                        <th scope="col">TTS Flight Number </th>
                                                        <th scope="col">Flight Date </th>
                                                        <th scope="col">Departure </th>
                                                        <th scope="col">Arrival </th>
                                                        <th scope="col">Comment English </th>
                                                        <th scope="col">Comment French </th>
                                                        <th scope="col">Delete </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {flightAssociationList.filter(flt => flt.legFlightNumber == element.flightNumber && flt.legFlightDate == element.flightDateOrg && flt.legOrigin == element.flightDep && flt.legDestination == element.flightArr).map((e, i) =>
                                                        <tr key={i}>
                                                            <td>{e.flightNumber}</td>
                                                            <td style={{ whiteSpace: "nowrap" }}>{e.flightDate.split("T")[0]} </td>
                                                            <td>{e.origin} </td>
                                                            <td>{e.destination} </td>
                                                            <td>{e.message.en} </td>
                                                            <td>{e.message.fr} </td>
                                                            <td style={{ textAlign: "center" }}><FontAwesomeIcon onClick={() => { deleteFlightAssociation(e.id) }} icon={faTrash} /> </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>}
                                    </div>}


                                    {!props.readOnly && <div className="row g-0">
                                        <div className="col-sm-12">
                                            <label onClick={() => setFocusedDelaySynopsis(element.id)} className="col-form-label"><strong>Add Delay Synopsis: </strong></label>
                                        </div>
                                    </div>}
                                    {showDelaySynopsis && focusedElementId == element.id && canAddAfdnDelaySynopsis() &&
                                        <div className="row g-0">
                                            <div className="col-sm-2">
                                                <label className="col-form-label"><strong>Tarmac Delay</strong></label>
                                            </div>
                                            <div className="col-sm-4">
                                                {["Yes", "No"].map((e, i) =>
                                                    <div key={i} className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            value={e}
                                                            checked={element.tarmacDelay == (e == "Yes")}
                                                            type="radio"
                                                            name={`tarmacDelay${index}`}
                                                            onChange={(event) => setAfdnDetailsProperty(element, "tarmacDelay", event.target.value == "Yes")}
                                                        />
                                                        <label className="form-check-label">
                                                            {e}
                                                        </label>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-sm-1">
                                                <label className="col-form-label"><strong>Status</strong></label>
                                            </div>
                                            <div className="col-sm-2 mb-1">
                                                <select
                                                    className="form-select form-select-sm"
                                                    value={element.delayStatus || ""}
                                                    onChange={(event) => setAfdnDetailsProperty(element, "delayStatus", event.target.value)}
                                                >
                                                    <option value=''> --Select Status</option>
                                                    {['Draft', 'Processed'].map((element, index) =>
                                                        <option key={index} value={element}>{element}</option>
                                                    )}
                                                </select>
                                            </div>

                                            <div className="col-sm-11">
                                                <textarea
                                                    value={element.delaySynopsis || ""}
                                                    onChange={(e) => setAfdnDetailsProperty(element, "delaySynopsis", e.target.value)}
                                                    className="form-control"
                                                    rows={3}
                                                    style={{ fontFamily: "Courier", fontSize: "12px", fontWeight: "bold" }}
                                                />
                                            </div>
                                            <div className="col-12 mt-2 mb-2">
                                                <button disabled={!afdnFormDetailsElementHasPrimaryReason(element.id) || !delaySynopsisHasChanged(element) || delaySynopsisEmpty(element) || saveSynopsisLoading} onClick={() => saveDelaySynopsis(element)} className="btn btn-secondary btn-sm">
                                                    {saveSynopsisLoading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                                                    {!saveSynopsisLoading && <span>Save</span>}
                                                </button>
                                            </div>
                                            {saveSynopsisError && <div className="col-12 mt-2 mb-2">
                                                <span className="danger">AFDN Synopsis is not updated. Please try again !</span>
                                            </div>}

                                        </div>}



                                </div>)}
                            <div className="col mt-2 p-0">

                                {!props.readOnly &&
                                    <button disabled={saveEmailLoading || saveLoading || !afdnFormHasChanged()} onClick={saveAfdnForm} className="btn btn-secondary btn-sm me-1">
                                        {saveLoading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                                        {!saveLoading && <span>Save</span>}
                                    </button>
                                }

                                {!props.readOnly && <button onClick={saveAndEmailAfdnForm} disabled={saveEmailLoading || saveLoading || !afdnFormHasChanged() || !afdnFormDetailsHasPrimaryReason() || !notifyUncheckedWhenNoPrimaryReason() || !notifyChecked()} className="btn btn-secondary btn-sm me-1">
                                    {saveEmailLoading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                                    {!saveEmailLoading && <span> Save & Email</span>}
                                </button>}

                                {!props.readOnly && <button disabled={saveEmailLoading || saveLoading} onClick={resetForm} className="btn btn-secondary btn-sm me-1">Reset</button>}

                                <button disabled={saveEmailLoading || saveLoading} onClick={() => navigate.goBack()} className="btn btn-secondary btn-sm me-1">Close</button>

                            </div>
                            {saveError || saveEmailError && <div className="col mt-2 p-0">
                                <span className="danger">AFDNFORM is not updated. Please try again !</span>
                            </div>}


                            {afdnForm && afdnForm.afdnHistory.length > 0 && <div className="col p-0 mt-4">
                                <div><strong>History: </strong></div>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped table-sm">
                                        <thead className=" custom-color-head custom-color-head">
                                            <tr>
                                                <th scope="col">IssuedDate </th>
                                                <th scope="col">IssuedBy </th>
                                                <th scope="col">Form Status </th>
                                                <th scope="col">Processing </th>
                                                <th scope="col">Open Link </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {afdnForm.afdnHistory.map((element, index) =>
                                                <tr key={index}>
                                                    <td>{getFormattedDate(new Date(element.issuedDate))}</td>
                                                    <td>{element.issuedBy}</td>
                                                    <td>{versionNumbers?.filter(e => e.id == element.afdnVersionNumber)[0]?.description}</td>
                                                    <td>{element.processing}</td>
                                                    <td>{element.emailLink ? <Link to={`/emailtemplate/0/${element.emailLink}`} target="_blank"> View Email </Link> : null}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}


                            {afdnForm && afdnForm.afdnDelaySynopsisHistory.length > 0 &&
                                <div className="col p-0 mt-4">
                                    <div><strong>Delay Synopsis History: </strong></div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped table-sm">
                                            <thead className=" custom-color-head custom-color-head">
                                                <tr>
                                                    <th scope="col">IssuedDate </th>
                                                    <th scope="col">IssuedBy </th>
                                                    <th scope="col">Processed </th>
                                                    <th scope="col">Open Link </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {afdnForm.afdnDelaySynopsisHistory.map((element, index) =>
                                                    <tr key={index}>
                                                        <td>{getFormattedDate(new Date(element.issuedDate))}</td>
                                                        <td>{element.issuedBy}</td>
                                                        <td>{element.processed}</td>
                                                        <td>{element.emailLink ? <Link to={`/emailtemplate/1/${element.emailLink}`} target="_blank"> View Email </Link> : null}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>}
            {afdnFormError &&
                <div className="container-fluid create-form mt-4">
                    <div className="col">
                        <div className="row">
                            <div className="alert alert-danger" role="alert">
                                {afdnFormError}
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default AfdnForm;
