import "./styles.css";
import { faFileText } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageLocation from "../PageLocation";
import { Link } from "react-router-dom";
import { SetStateAction, useContext, useEffect, useState } from "react";
import SwgContext from "../../ApprStore/swg-context";
import { IFlightSchedule } from "../../models";
import DeleteConfirmation from "./DeleteConfirmation"
const FlightScheduleManagement = () => {
  const swgContext = useContext(SwgContext);
  const [flightNumber, setFlightNumber] = useState("Flight");
  const[id,setflId]=useState(Number);
  const [show, setShow] = useState(false);
  const handleFlightSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    swgContext.setFlightRoute("");
    swgContext.setFlydetails(null);
    swgContext.setSwgFlightsLoading(true);
    const FlNO = e.target.value;
    swgContext.setFlightNumber(FlNO);
    
    const RoutData = swgContext.flightsDetails?.filter(item => item.flightNo === FlNO);
    let FlightRoute = RoutData?.map(fly => fly.gateway + "-" + fly.destination);

  };


  const handelSaveSchedule = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    swgContext.SaveFlightSchedule();
  };
  const handleFlightRouteSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    swgContext.setFlightRoute(e.target.value);
   
    if (e.target.value.includes("-")) {
      const flyRoute = e.target.value.split("-");
      let fly = swgContext.flightsDetails?.filter((f) => f.gateway === flyRoute[0] && f.destination === flyRoute[1])[0];
      fly && swgContext.setFlydetails(fly);
      if (fly !== undefined) {
        fly.flightRoute = swgContext.flightRoute;
        fly.date = swgContext.dateInput === null ? "" : swgContext.dateInput;
        swgContext.getRahFlights(fly.date, fly.flightNo, fly.flightRoute.split("-")[0], fly.flightRoute.split("-")[1]);
      }
    }
    else{
      swgContext.setResultMsg("");
      


    }
  };
  const handelDeleteSchedule = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setflId(swgContext.rahID);
    setShow(true);  
    
  };

  useEffect(() => {
    swgContext.setSwgFlightsLoading(false);
    const depDate = swgContext.dateInput?.toLocaleString();
    swgContext.setResultMsgCss("col-form-label floatRight");
    swgContext.setResultMsg("");
    swgContext.setFlightRoutes([]);
    swgContext.setFlightNumbers([]);
    swgContext.setFlightsDetails([]);
    swgContext.setFlydetails(null);
    swgContext.setSaveSuccess(false);
    depDate && swgContext.getFlightNumbers(depDate);
  }, [swgContext.dateInput]);

  useEffect(() => {
    swgContext.setResultMsg("");
    swgContext.setFlightRoutes([]);
    swgContext.setFlightsDetails([]);
    swgContext.dateInput && swgContext.flightNumber !="Flight" && swgContext.getFlightDetails(swgContext.dateInput, swgContext.flightNumber);

  }, [swgContext.flightNumber]);

  useEffect(() => {
    if (swgContext.flightRoute.includes("-")) {
      const flyRoute = swgContext.flightRoute.split("-");
      let fly = swgContext.flightsDetails?.filter((f) => f.gateway === flyRoute[0] && f.destination === flyRoute[1])[0];
      if (fly !== undefined) {
        fly.flightRoute = swgContext.flightRoute;
        fly.date = swgContext.dateInput === null ? "" : swgContext.dateInput;
        swgContext.getRahFlights(fly.date, fly.flightNo, fly.flightRoute.split("-")[0], fly.flightRoute.split("-")[1]);
      }

    }
  }, [swgContext.flightNumber, swgContext.flightRoute]);

  const handlerevisedtime = (e: React.ChangeEvent<HTMLInputElement>) => {
    swgContext.setRevisedDepDate(e.target.value)
  }
  const handleIsCancelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    swgContext.setCanceled(e.target.checked);
  };
  return (
    <>
      
      <div className="container-fluid d-flex justify-content-center filter mt-4">
        <div className="col p-3">
          <form>
            <div className="row pb-4">
              <div className="col-sm-2">
                <label className="col-form-label">Departure Date: * </label>
              </div>
              <div className="col-sm-2">
                <input
                  type="date"
                  id="txtFlightDepDt"
                  className="form-control"
                  onChange={(e) => swgContext.setDateInput(e.target.value)}

                />
              </div>
              <div className="col-sm-1">
                <label className="col-form-label">Flight: </label>
              </div>
              <div className="col-sm-2">

                <select
                  id="ddlFlight"
                  className="form-control"
                  onChange={handleFlightSelect}
                >
                  <option value="Flight">Flight</option>
                  {swgContext.flightNumbers && swgContext.flightNumbers !== null &&
                    swgContext.flightNumbers.map((flight) =>
                      <option key={flight} value={flight}>{flight}</option>
                    )}


                </select>
              </div>
              <div className="col-sm-1">
                <label className="col-form-label">Route:</label>
              </div>
              <div className="col-sm-2">
                <select name="ddlFlightRoute" value={swgContext.flightRoute} className="form-control" onChange={handleFlightRouteSelect}>
                  <option value="Flight Route">Flight Route</option>
                  {swgContext.flightRoutes?.map((item) =>
                    <option key={item} value={item}>{item}</option>
                  )}
                </select>
              </div>
            </div>
            {swgContext.flydetails &&  !swgContext.swgFlightsApiError &&
              <>
                <div className="row pb-3 boxdivider"></div>
                <div className="row">
                  <div className="col-sm-2">
                    <label className="col-form-label">Departure Date:</label>
                  </div>
                  <div className="col-sm-2">
                    <label className="col-form-label boldfont" id="txtDepDateVal">{swgContext.flydetails.date}</label>
                  </div>
                  <div className="col-sm-2">
                    <label className="col-form-label">Local Departure Time:</label>
                  </div>
                  <div className="col-sm-2">
                    <label className="col-form-label boldfont" id="txtSchDeptimeVal">{swgContext.flydetails.timeShdDpt}</label>
                  </div>
                  <div className="col-sm-2">
                    <label className="col-form-label"> Local Arrival Time:</label>
                  </div>
                  <div className="col-sm-2">
                    <label className="col-form-label boldfont" id="txtSchArrtimeVal">{swgContext.flydetails.timeShdArr}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <label className="col-form-label">Flight Number:</label>
                  </div>
                  <div className="col-sm-2">
                    <label className="col-form-label boldfont" id="txtFlNoVal">{swgContext.flydetails.flightNo}</label>
                  </div>
                  <div className="col-sm-2">
                    <label className="col-form-label">Flight Route:</label>
                  </div>
                  <div className="col-sm-2">
                    <label className="col-form-label boldfont" id="txtFlDepVal">{swgContext.flydetails.gateway}-{swgContext.flydetails.destination}`</label>
                  </div>
                </div>
                <div className="row pb-3 boxdivider"></div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="col-sm-8 col-form-label">
                      Revised departure date/time (Local Station Time)
                    </div>
                    <div className="col-sm-7">
                      <input
                        type="datetime-local"
                        id="txtRevisrdDepDt"
                        className="form-control"
                        value={swgContext.revisedDepDate}
                        onChange={handlerevisedtime}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="col-sm-8 col-form-label">
                      Revised arrival date/time (Local Station Time)
                    </div>

                    <div className="col-sm-7">
                      <input
                        type="datetime-local"
                        id="txtRevisrdArrDt"
                        className="form-control"
                        value={swgContext.revisedArrDate}
                        onChange={(e) => swgContext.setRevisedArrDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-sm-6">
                    <input type="checkbox" id="chkCancel" name="chkCancel" defaultChecked={false} checked={swgContext.isCanceled} onChange={handleIsCancelChange} /> Canceled

                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-sm-6">
                    <label className="col-form-label">Last Updated: </label>
                    <label className="col-form-label boldfont">{swgContext.RAHflights?.updatedDate}</label>
                  </div>
                  <div className="col-sm-6">
                    <label className="col-form-label">Updated by: </label>
                    <label className="col-form-label boldfont">{swgContext.RAHflights?.updatedBy}</label>


                  </div>

                </div>
                <div className="row pt-3">
                  <div className="col-sm-1">
                    <button onClick={handelSaveSchedule} className="btn custom-button" >
                      <span>Save</span>
                    </button>

                  </div>
                 
                    <div className="col-sm-2">
                      <button disabled={swgContext.SaveSuccess!== true} onClick={handelDeleteSchedule} className="btn custom-button" >
                        <span>Delete</span>
                      </button>
                    </div>
                  <div className="col-sm-8">
                    <label id="lblmsg" className={swgContext.resultMsgCss}>{swgContext.resultMsg}</label>
                  </div>
                </div>
              </>
            }
          </form>
        </div>
      </div>
      <DeleteConfirmation 
        id={swgContext.rahID} 
        onDeleteFlightSch={swgContext.onDeleteFlightSch}
        show={show}
        setShow={setShow}  
      />
      {swgContext.swgFlightsLoading && <div className="container-fluid">
                <div className="row g-0 d-flex justify-content-center">
                    <div className="spinner-border m-5" role="status"> </div>
                </div>
      </div>}
    </>
  );
  
};

export default FlightScheduleManagement;
