import { useEffect, useState } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import { Link, useParams } from 'react-router-dom';
import { IAFDNDelaySynopsisEmail, IAFDNEmail } from '../../models';

const EmailTemplate = () => {

    const [email, setEmail] = useState<IAFDNEmail | IAFDNDelaySynopsisEmail | null>(null);
    const [emailHeader, setEmailHeader] = useState<string>("");
    const { type, emailId } = useParams<{ type: string, emailId: string }>();

    const { authState, oktaAuth } = useOktaAuth();

    const setEmailHead = () => {
        return `<strong>From: </strong> ${email?.sendBy} <br/>
                 <strong>Sent on: </strong> ${email?.sendDateTime} <br/>
                 <strong>Sent by: </strong> ${email?.senderName} <br/>
                 <strong>To: </strong> ${email?.sendToEmails} <br/>
                 <strong>Cc: </strong> ${email?.ccToEmails} <br/>
                 <strong>Subject: </strong> ${email?.emailSubject} <br/>`;
    }

    const getEmail = () => {
        fetch(`${process.env.REACT_APP_AIR_PRC_API_URL}flightdelay/email/${type}/${emailId}`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json()
            })
            .then(data => {
                setEmail(data);
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        getEmail();
    }, []);

    return (
        <div dangerouslySetInnerHTML={{ __html: email?.bodyHtml.replace("<tr>", `<tr>${setEmailHead()}`) || "" }} />
    )
}

export default EmailTemplate;
