import React, { useState, useEffect, SetStateAction } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import {
  IFlight,
  IApprFormTbl,
  IformHistory,
  IApprDelayCategory,
  IApprFlightType,
  IApprFormStatus,
  IApprDelayTime,
  IApprDelayReason,
  IApprPayRate,
  IApprFormPaxTbl,
  IApprBookingType,
  IApprClaimStatus,
  IApprClaimType,
  IApprCompensationType,
  IApprPaymentMethod,
  IApprFormPaxHistoryTbl
} from '../models';
import { getFormattedDate } from '../utility/Utility';


interface IProps {
  children?: React.ReactNode

}

const defaultApprForm: IApprFormTbl = {
  id: 0,
  flightDateLocal: new Date(),
  flightNumber: '',
  dep: '',
  arr: '',
  delayCategory: 0,
  flightType: 0,
  delayTime: 0,
  voucherAmount: 0,
  mcAmount: 0,
  comments: '',
  depTime: '',
  arrTime: '',
  depCity: '',
  arrCity: '',
  commentsFr: '',
  fdReasonCode: '',
  statusId: 3,
  FlightProductCode: '',
}

//All Functions Or States Used In More Than a File Are In this Context.
export type apprContextType = {

  //Appr Flight Search
  dateInput: string | null;
  setDateInput: (value: SetStateAction<string | null>) => void,
  flights: IFlight[] | null;
  setFlights: (value: SetStateAction<IFlight[] | null>) => void,
  findFlights: () => void,
  apprFlightsLoading: boolean;
  apprFlightsApiError: string;


  //ApprFormMainPage
  apprForm: IApprFormTbl;
  setApprForm: (value: SetStateAction<IApprFormTbl>) => void,
  apprFormRefrence: IApprFormTbl;
  setApprFormRefrence: (value: SetStateAction<IApprFormTbl>) => void,
  formHistory: IformHistory[] | null;
  setFormHistory: (value: SetStateAction<IformHistory[] | null>) => void,
  aprDelayCategory: IApprDelayCategory[] | null;
  setApprDelayCategory: (value: SetStateAction<IApprDelayCategory[] | null>) => void,
  aprFlightType: IApprFlightType[] | null;
  setApprFlightType: (value: SetStateAction<IApprFlightType[] | null>) => void,
  aprDelayTime: IApprDelayTime[] | null;
  setApprDelayTime: (value: SetStateAction<IApprDelayTime[] | null>) => void,
  apprPayRate: IApprPayRate[] | null;
  setAprPayRate: (value: SetStateAction<IApprPayRate[] | null>) => void,
  apprDelayReason: IApprDelayReason[] | null;
  setApprDelayReason: (value: SetStateAction<IApprDelayReason[] | null>) => void,
  apprFormStatus: IApprFormStatus[] | null;
  setApprFormStatus: (value: SetStateAction<IApprFormStatus[] | null>) => void,
  getApprFormData: (formId: string, flightDateLocal?: string, dep?: string, arr?: string, flightNumber?: string) => void,
  mainApprLoading: boolean;
  mainApprApiError: string;

  //ApprPax
  apprFormPax: IApprFormPaxTbl[] | null;
  setApprFormPax: (value: SetStateAction<IApprFormPaxTbl[] | null>) => void,
  apprBookingType: IApprBookingType[] | null;
  setApprBookingType: (value: SetStateAction<IApprBookingType[] | null>) => void,
  apprClaimStatus: IApprClaimStatus[] | null;
  setApprClaimStatus: (value: SetStateAction<IApprClaimStatus[] | null>) => void,
  apprClaimType: IApprClaimType[] | null;
  setApprClaimType: (value: SetStateAction<IApprClaimType[] | null>) => void,
  apprCompensationType: IApprCompensationType[] | null;
  setApprCompensationType: (value: SetStateAction<IApprCompensationType[] | null>) => void,
  apprPaymentMethod: IApprPaymentMethod[] | null;
  setApprPaymentMethod: (value: SetStateAction<IApprPaymentMethod[] | null>) => void,
  singleApprFormPax: IApprFormPaxTbl | null;
  setSingleApprFormPax: (value: SetStateAction<IApprFormPaxTbl | null>) => void,
  singleApprFormPaxReference: IApprFormPaxTbl | null;
  setSingleApprFormPaxReference: (value: SetStateAction<IApprFormPaxTbl | null>) => void,
  formPaxHistory: IApprFormPaxHistoryTbl[] | null;
  setFormPaxHistory: (value: SetStateAction<IApprFormPaxHistoryTbl[] | null>) => void,


};

const defaultContext = {

  //Appr Flight Search
  flights: null,
  dateInput: null,
  apprFlightsLoading: false,
  apprFlightsApiError: "",
  setDateInput: () => { },
  setFlights: () => { },
  findFlights: () => { },

  //ApprFormMainPage
  apprForm: defaultApprForm,
  setApprForm: () => { },
  apprFormRefrence: defaultApprForm,
  setApprFormRefrence: () => { },
  formHistory: null,
  setFormHistory: () => { },
  aprDelayCategory: null,
  setApprDelayCategory: () => { },
  aprFlightType: null,
  setApprFlightType: () => { },
  aprDelayTime: null,
  setApprDelayTime: () => { },
  apprPayRate: null,
  setAprPayRate: () => { },
  apprDelayReason: null,
  setApprDelayReason: () => { },
  apprFormStatus: null,
  setApprFormStatus: () => { },
  getApprFormData: (formId: string, flightDateLocal?: string, dep?: string, arr?: string, flightNumber?: string) => { },
  mainApprLoading: true,
  mainApprApiError: "",

  //ApprFormPax
  apprFormPax: null,
  setApprFormPax: () => { },
  apprBookingType: null,
  setApprBookingType: () => { },
  apprClaimStatus: null,
  setApprClaimStatus: () => { },
  apprClaimType: null,
  setApprClaimType: () => { },
  apprCompensationType: null,
  setApprCompensationType: () => { },
  apprPaymentMethod: null,
  setApprPaymentMethod: () => { },
  singleApprFormPax: null,
  setSingleApprFormPax: () => { },
  singleApprFormPaxReference: null,
  setSingleApprFormPaxReference: () => { },
  formPaxHistory: null,
  setFormPaxHistory: () => { },

}
const ApprContext = React.createContext<apprContextType>(defaultContext);

export const ApprContextProvider = (props: IProps) => {

  //Appr Flights Search 
  const [flights, setFlights] = useState<IFlight[] | null>(null);
  const [dateInput, setDateInput] = useState<string | null>(null);
  const [apprFlightsLoading, setApprFlightsLoading] = useState(false);
  const [apprFlightsApiError, setApprFlightsApiError] = useState<string>("");

  //ApprFormMain
  const [apprForm, setApprForm] = useState<IApprFormTbl>(defaultApprForm);
  const [apprFormRefrence, setApprFormRefrence] = useState<IApprFormTbl>(defaultApprForm);
  const [formHistory, setFormHistory] = useState<IformHistory[] | null>(null);
  const [aprDelayCategory, setApprDelayCategory] = useState<IApprDelayCategory[] | null>(null);
  const [aprFlightType, setApprFlightType] = useState<IApprFlightType[] | null>(null);
  const [aprDelayTime, setApprDelayTime] = useState<IApprDelayTime[] | null>(null);
  const [apprPayRate, setAprPayRate] = useState<IApprPayRate[] | null>(null);
  const [apprDelayReason, setApprDelayReason] = useState<IApprDelayReason[] | null>(null);
  const [apprFormStatus, setApprFormStatus] = useState<IApprFormStatus[] | null>(null);
  const [mainApprApiError, setMainApprApiError] = useState<string>("");
  const [mainApprLoading, setMainApprLoading] = useState(false);

  //ApprPax
  const [apprFormPax, setApprFormPax] = useState<IApprFormPaxTbl[] | null>(null);
  const [apprBookingType, setApprBookingType] = useState<IApprBookingType[] | null>(null);
  const [apprClaimStatus, setApprClaimStatus] = useState<IApprClaimStatus[] | null>(null);
  const [apprClaimType, setApprClaimType] = useState<IApprClaimType[] | null>(null);
  const [apprCompensationType, setApprCompensationType] = useState<IApprCompensationType[] | null>(null);
  const [apprPaymentMethod, setApprPaymentMethod] = useState<IApprPaymentMethod[] | null>(null);
  const [singleApprFormPax, setSingleApprFormPax] = useState<IApprFormPaxTbl | null>(null);
  const [singleApprFormPaxReference, setSingleApprFormPaxReference] = useState<IApprFormPaxTbl | null>(null);
  const [formPaxHistory, setFormPaxHistory] = useState<IApprFormPaxHistoryTbl[] | null>(null);

  const { authState, oktaAuth } = useOktaAuth();


  const findFlights = () => {
    setApprFlightsLoading(true);
    fetch(`${process.env.REACT_APP_APPR_FDC_API_URL}/ApprForms/?flightsDate=${dateInput}`, {
      headers: {
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      method: "GET",
    })
      .then(res => {
        if (!res.ok) {
          throw new Error('Something went wrong! Please try again');
        }
        return res.json()
      })
      .then(data => {
        setApprFlightsLoading(false);
        setFlights(data);
        setApprFlightsApiError("");
      })
      .catch((error) => {
        setApprFlightsLoading(false);
        setApprFlightsApiError("Something Went Wrong !");
      });
  };

  const getApprFormData = (formId: string, flightDateLocal?: string, dep?: string, arr?: string, flightNumber?: string) => {
    setMainApprLoading(true);
    setMainApprApiError("");
    let params: URLSearchParams | null = null;
    if (flightDateLocal && dep && arr && flightNumber) {
       params = new URLSearchParams({
        flightDate: getFormattedDate(new Date(flightDateLocal)),
        flightNumber: flightNumber,
        dep: dep,
        arr: arr,
      });
    }
    fetch(`${process.env.REACT_APP_APPR_FDC_API_URL}/ApprForms/${formId}?` + params, {
      headers: {
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      method: "GET",
    })
      .then(res => {
        if (!res.ok) {
          throw new Error('Something went wrong! Please try again');
        }
        return res.json()
      })
      .then(data => {

        if (!data.apprForm) {
          throw ("Incorrect Data");
        }
        setApprForm(() =>
        ({
          ...data.apprForm,
          voucherAmount: data.apprPayRate && data.apprForm.statusId == 2 ? data.apprPayRate.filter((elem: { flightType: number; delayTime: number; compensationType: number; }) => elem.flightType == data.apprForm?.flightType && elem.delayTime == data.apprForm.delayTime && elem.compensationType == 2)[0]?.amount | 0 : data.apprForm.voucherAmount,
          mcAmount: data.apprPayRate && data.apprForm.statusId == 2 ? data.apprPayRate.filter((elem: { flightType: number; delayTime: number; compensationType: number; }) => elem.flightType == data.apprForm?.flightType && elem.delayTime == data.apprForm.delayTime && elem.compensationType == 1)[0]?.amount | 0 : data.apprForm.mcAmount,
        }))
        setApprFormRefrence(data.apprForm);
        setFormHistory(data.apprFormHistory);
        setApprDelayCategory(data.apprDelayCategory);
        setApprFlightType(data.apprFlightType);
        setApprDelayTime(data.apprDelayTime);
        setAprPayRate(data.apprPayRate);
        setApprFormStatus(data.apprFormStatus);
        setApprDelayReason(data.apprDelayReason);
        setMainApprApiError("");
        setMainApprLoading(false);
      })
      .catch((error) => {
        setMainApprLoading(false);
        setMainApprApiError("Something Went Wrong !");
      });
  }

  useEffect(() => {

  }, []);


  return (
    <ApprContext.Provider
      value={{
        //search appr flights
        dateInput,
        setDateInput,
        flights,
        setFlights,
        findFlights,
        apprFlightsLoading,
        apprFlightsApiError,

        //Appr Form
        apprForm,
        setApprForm,
        apprFormRefrence,
        setApprFormRefrence,
        formHistory,
        setFormHistory,
        aprDelayCategory,
        setApprDelayCategory,
        aprFlightType,
        setApprFlightType,
        aprDelayTime,
        setApprDelayTime,
        apprPayRate,
        setAprPayRate,
        apprDelayReason,
        setApprDelayReason,
        apprFormStatus,
        setApprFormStatus,
        getApprFormData,
        mainApprApiError,
        mainApprLoading,

        //Appr Form Pax
        apprFormPax,
        setApprFormPax,
        apprBookingType,
        setApprBookingType,
        apprClaimStatus,
        setApprClaimStatus,
        apprClaimType,
        setApprClaimType,
        apprCompensationType,
        setApprCompensationType,
        singleApprFormPax,
        setSingleApprFormPax,
        singleApprFormPaxReference,
        setSingleApprFormPaxReference,
        apprPaymentMethod,
        setApprPaymentMethod,
        formPaxHistory,
        setFormPaxHistory
      }}
    >
      {props.children}
    </ApprContext.Provider>
  );
};

export default ApprContext;
