import { read, utils, writeFile } from 'xlsx';
import { useEffect, useState } from 'react';
import PageLocation from '../PageLocation';
import './styles.css';
import { IApprClaimModel, IApprClaimStatus, IApprClaimType, IApprCompensationType, IClaimBulkFilter } from '../../models';
import { useOktaAuth } from "@okta/okta-react";

const ApprBulkClaimProcessing = () => {

    const [apprClaimStatus, setApprClaimStatus] = useState<IApprClaimStatus[] | null>(null);
    const [apprClaimType, setApprClaimType] = useState<IApprClaimType[] | null>(null);
    const [exportIsloading, setExportIsLoading] = useState<boolean>(false);
    const [uploadIsloading, setUploadIsLoading] = useState<boolean>(false);
    const [mstDataloading, setMstDataloading] = useState<boolean>(true);
    const [exportError, setExportError] = useState<string>("");
    const [uploadReturnedMessage, setUploadReturnedMessage] = useState<string>("");
    const [apprCompensationType, setApprCompensationType] = useState<IApprCompensationType[] | null>(null);
    const [apprClaimList, setApprClaimList] = useState<IApprClaimModel[] | null>(null);
    const [apprClaimListUploaded, setApprClaimListUploaded] = useState<IApprClaimModel[] | null>(null);
    const [uploadedFile, setUploadedFile] = useState<React.ChangeEvent<HTMLInputElement> | null>(null);
    const { authState, oktaAuth } = useOktaAuth();
    const [claimBulkFilter, setClaimBulkFilter] = useState<IClaimBulkFilter>({
        flightDateFrom: "",
        flightDateTo: "",
        ClaimDateFrom: "",
        ClaimDateTo: "",
        ClaimType: "",
        CompensationType: "",
        ClaimStatus: ""
    });


    useEffect(() => { getMstData() }, [])

    useEffect(() => { handleExport() }, [apprClaimList])

    useEffect(() => { saveBulkApprClaim() }, [apprClaimListUploaded])


    const getMstData = () => {
        fetch(`${process.env.REACT_APP_APPR_FDC_API_URL}/ApprClaimBulkInitialData`, {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json()
            })
            .then(data => {
                setApprClaimStatus(data.apprClaimStatus);
                setApprClaimType(data.apprClaimType);
                setApprCompensationType(data.apprCompensationType);
                setMstDataloading(false);
            })
            .catch((error) => {
                setMstDataloading(false);
            });
    }

    const getApprClaimBulk = () => {
        setExportIsLoading(true);
        fetch(`${process.env.REACT_APP_APPR_FDC_API_URL}/ApprBulkClaims?` + new URLSearchParams({
            flightDateFrom: claimBulkFilter.flightDateFrom,
            flightDateTo: claimBulkFilter.flightDateTo,
            ClaimDateFrom: claimBulkFilter.ClaimDateFrom,
            ClaimDateTo: claimBulkFilter.ClaimDateTo,
            ClaimType: claimBulkFilter.ClaimType,
            CompensationType: claimBulkFilter.CompensationType,
            ClaimStatus: claimBulkFilter.ClaimStatus
        }), {
            headers: {
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            },
            method: "GET",
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json();
            })
            .then(data => {
                setExportIsLoading(false);
                if (data.length) {
                    setApprClaimList(data);
                    setExportError("");
                }
                else {
                    setExportError("No Data Found !");
                }

            })
            .catch((error) => {
                setExportIsLoading(false);
                setExportError("Something went Wrong !");
            });
    }

    const saveBulkApprClaim = () => {
        if (apprClaimListUploaded?.length) {
            setUploadIsLoading(true);
            fetch(`${process.env.REACT_APP_APPR_FDC_API_URL}/ApprBulkClaims`, {
                method: 'POST',
                body: JSON.stringify(apprClaimListUploaded),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${oktaAuth.getAccessToken()}`
                },
            }).then(res => {
                if (!res.ok) {
                    throw new Error('Something went wrong! Please try again');
                }
                return res.json()
            })
                .then(async (data) => {
                    setUploadReturnedMessage(`${data} rows are affected`);
                    setUploadIsLoading(false);
                })
                .catch((error) => {
                    setUploadReturnedMessage("Something went Wrong!");
                    setUploadIsLoading(false);
                });;
        }
    }

    const handleExport = () => {
        if (apprClaimList?.length) {
            const headings = [[
                "ClaimPaxId",
                'FlightId',
                'FlightType',
                'FlightDate',
                'FlightNumber',
                'FlightLeg',
                'BookingNumber',
                'FirstName',
                'LastName',
                'BookingType',
                'BirthDate',
                'BirthDatePaxSubmitted',
                'ClaimType',
                'CompensationType',
                'CompensationAmount',
                'Email',
                'Phone',
                'PaymentMethod',
                'Address',
                'City',
                'Province',
                'PostCode',
                'ClaimConfirmationNo',
                'ClaimCreateDate',
                'SubmittedBy',
                'claimStatus',
                'ClaimStatusUpdateDate',
                'ClaimVoucherRedeemed',
                'Comments'
            ]];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, apprClaimList, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, `APPRClaim_${Date.now()}`);
            writeFile(wb, `APPRClaim_${Date.now()}.xlsx`);
        }
    }

    const handleImport = () => {
        const files = uploadedFile?.target.files;
        if (files?.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target?.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows: IApprClaimModel[] = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    if (rows.length) {
                        setApprClaimListUploaded(rows);
                    }
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    return (
        <>
            {mstDataloading &&
                <div className="container-fluid">
                    <div className="row g-0 d-flex justify-content-center">
                        <div className="spinner-border m-5" role="status"> </div>
                    </div>
                </div>
            }

            {!mstDataloading && <>
                <div className="container-fluid  d-flex justify-content-center filter mt-4">
                    <div className="col p-3">
                        <div className="row pb-4">
                            <div className="col-sm-2">
                                <label className="col-form-label-sm">Flight Date Range: </label>
                            </div>
                            <div className="col-sm-1">
                                <label className="col-form-label-sm">From: </label>
                            </div>
                            <div className="col-sm-3">
                                <input
                                    value={claimBulkFilter.flightDateFrom}
                                    onChange={(e) => setClaimBulkFilter((prevState) => ({ ...prevState, flightDateFrom: e.target.value }))}
                                    type="date"
                                    className="form-control form-control-sm" />
                            </div>
                            <div className="col-sm-1">
                                <label className="col-form-label-sm">To: </label>
                            </div>
                            <div className="col-sm-3">
                                <input
                                    value={claimBulkFilter.flightDateTo}
                                    onChange={(e) => setClaimBulkFilter((prevState) => ({ ...prevState, flightDateTo: e.target.value }))}
                                    type="date"
                                    className="form-control form-control-sm" />
                            </div>
                        </div>

                        <div className="row pb-4">
                            <div className="col-sm-2">
                                <label className="col-form-label-sm">Claim Date Range: </label>
                            </div>
                            <div className="col-sm-1">
                                <label className="col-form-label-sm"><span className="danger">*</span>From: </label>
                            </div>
                            <div className="col-sm-3">
                                <input
                                    value={claimBulkFilter.ClaimDateFrom}
                                    onChange={(e) => setClaimBulkFilter((prevState) => ({ ...prevState, ClaimDateFrom: e.target.value }))}
                                    type="date"
                                    className="form-control form-control-sm" />
                            </div>
                            <div className="col-sm-1">
                                <label className="col-form-label-sm"><span className="danger">*</span>To: </label>
                            </div>
                            <div className="col-sm-3">
                                <input
                                    value={claimBulkFilter.ClaimDateTo}
                                    onChange={(e) => setClaimBulkFilter((prevState) => ({ ...prevState, ClaimDateTo: e.target.value }))}
                                    type="date"
                                    className="form-control form-control-sm" />
                            </div>
                        </div>

                        <div className="row pb-4">
                            <div className="col-sm-2">
                                <label className="col-form-label-sm">Claim Type: </label>
                            </div>
                            <div className="col-sm-3">
                                <select
                                    className="form-select form-select-sm"
                                    value={claimBulkFilter?.ClaimType || ''}
                                    onChange={(e) => setClaimBulkFilter((prevState) => ({ ...prevState, ClaimType: e.target.value }))}>
                                    <option value={''}>--Please Select</option>
                                    {apprClaimType && apprClaimType.map((element, index) => <option key={index} value={element.id || ''}>{element.description}</option>
                                    )}
                                </select>
                            </div>
                        </div>


                        <div className="row pb-4">
                            <div className="col-sm-2">
                                <label className="col-form-label-sm">Compensation Type: </label>
                            </div>
                            <div className="col-sm-3">
                                <select
                                    className="form-select form-select-sm"
                                    value={claimBulkFilter?.CompensationType || ''}
                                    onChange={(e) => setClaimBulkFilter((prevState) => ({ ...prevState, CompensationType: e.target.value }))}>
                                    <option value={''}>--Please Select</option>
                                    {apprCompensationType && apprCompensationType.map((element, index) => <option key={index} value={element.id || ''}>{element.description}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-2">
                                <label className="col-form-label-sm">Claim Status: </label>
                            </div>
                            <div className="col-sm-3">
                                <select
                                    className="form-select form-select-sm"
                                    value={claimBulkFilter?.ClaimStatus || ''}
                                    onChange={(e) => setClaimBulkFilter((prevState) => ({ ...prevState, ClaimStatus: e.target.value }))}>
                                    <option value={''}>--Please Select</option>
                                    {apprClaimStatus && apprClaimStatus.map((element, index) => <option key={index} value={element.id || ''}>{element.description}</option>
                                    )}
                                </select>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-12 mt-4">
                                <button disabled={exportIsloading || !claimBulkFilter.ClaimDateFrom || !claimBulkFilter.ClaimDateTo} onClick={getApprClaimBulk} className="btn btn-sm custom-button">
                                    {exportIsloading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                                    {!exportIsloading && <span> Download </span>}
                                </button>
                            </div>

                            {exportError &&
                                <div className="col-sm-12 mt-4">
                                    <span className="danger">{exportError}</span>
                                </div>}

                        </div>

                    </div>
                </div><div className="container-fluid  d-flex justify-content-center filter mt-4">
                    <div className="col p-3">
                        <div className="row">

                            <div className="col-sm-6">
                                <label className="form-label">Upload File:</label>
                                <input onChange={setUploadedFile} className="form-control" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" id="formFile" />
                            </div>

                            <div className="col-sm-12 mt-2">
                                <button disabled={!uploadedFile || uploadIsloading} onClick={handleImport} className="btn btn-sm custom-button">
                                    {uploadIsloading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                                    {!uploadIsloading && <span> Upload </span>}
                                </button>
                            </div>
                            {uploadReturnedMessage &&
                                <div className="col-sm-12 mt-4">
                                    <span>{uploadReturnedMessage}</span>
                                </div>}
                        </div>
                    </div>
                </div></>}

        </>
    )
}

export default ApprBulkClaimProcessing;
