import React, { useState,useEffect, useContext} from "react";
import SwgContext from "../../ApprStore/swg-context";
import { Modal, Button } from "react-bootstrap";

interface IProps {  
  id:number; 
  onDeleteFlightSch: (id:Number) => void;
  show:boolean;
  setShow:(bool:boolean)=>void;  
}
const DeleteConfirmation : React.FunctionComponent<IProps> = props => {
  
  const[id,setflId]=useState(Number);
  useEffect(() => setflId(props.id), [props]);
  const onConfirm=()=>{
    props.onDeleteFlightSch(id); 
    props.setShow(false);
   

  };
  const onClose=()=>{
    props.setShow(false);   
  }
  return (
      <>
      <Modal
        show={props.show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
      <Modal.Header>
        <Modal.Title>Confirm Flight Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this selected Flight?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onConfirm}>
          Ok
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
    </>
    );
}

export default DeleteConfirmation;


