import { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import PageLocation from '../PageLocation';
import { getFormattedDate } from '../../utility/Utility';
import './styles.css';
import ApprContext from '../../ApprStore/appr-context';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { useOktaAuth } from "@okta/okta-react";
import { IformHistory } from '../../models';

interface IProps {
    readOnly?: boolean;
}

const ApprAdminCreate = (props: IProps) => {

    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [confirmIsLoading, setConfirmIsLoading] = useState(false);
    const [saveApiError, setSaveApiError] = useState<string | null>(null);

    const { flightDateLocal, dep, arr, flightNumber, formId } = useParams<{ flightDateLocal: string, dep: string, arr: string, flightNumber: string, formId: string }>();
    const apprContext = useContext(ApprContext);
    const navigate = useHistory();

    const { authState, oktaAuth } = useOktaAuth();


    useEffect(() => {
        if (formId && (formId == "-1" || formId != apprContext.apprForm.id.toString()))
            apprContext.getApprFormData(formId, flightDateLocal, dep, arr, flightNumber);

    }, []);

    useEffect(() => {
        if (apprContext.apprForm.statusId == 3 && apprContext.apprFormRefrence.statusId != apprContext.apprForm.statusId)
            saveApprForm(true);

    }, [apprContext.apprForm.statusId])


    const saveApprForm = (confirm: boolean) => {
        !confirm ? setSaveIsLoading(true) : setConfirmIsLoading(true);
        let params: URLSearchParams | null = !confirm ? new URLSearchParams({}) : new URLSearchParams({
            confirm: confirm.toString(),
        });

        let apprForm = apprContext.apprForm;
        let newHistory: IformHistory = {
            apprFormId: apprContext.apprForm.id,
            processing: "",
            submittedDate: new Date(),
            submittedBy: authState?.idToken?.claims['email'] || "",
            emailLink: ""
        };

        newHistory.processing = newHistory.processing + `Update the Delay Time as: ${apprContext.aprDelayTime?.filter(e => e.id == apprContext.apprForm?.delayTime)[0]?.description}; `;
        newHistory.processing = newHistory.processing + `Voucher Amount as: ${apprContext.apprForm.voucherAmount}; `;
        newHistory.processing = newHistory.processing + `MC Amount as: ${apprContext.apprForm.mcAmount}; `;
        newHistory.processing = newHistory.processing + `Status as: ${apprContext.apprFormStatus?.filter(e => e.id == apprContext.apprForm?.statusId)[0]?.description}; `;
        const APPR_FDC_API_URL = apprContext.apprForm.id > 0 ? `${process.env.REACT_APP_APPR_FDC_API_URL}/ApprForms/${apprContext.apprForm.id}?` : `${process.env.REACT_APP_APPR_FDC_API_URL}/ApprForms`;
        fetch(APPR_FDC_API_URL + params, {
            method: apprContext.apprForm.id > 0 ? 'PUT' : 'POST',
            body: JSON.stringify({ apprForm, newHistory }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${oktaAuth.getAccessToken()}`
            },
        }).then(res => {
            if (!res.ok) {
                throw new Error('Something went wrong! Please try again');
            }
            return res.json()
        })
            .then(async (data) => {
                !confirm ? setSaveIsLoading(false) : setConfirmIsLoading(false);
                apprContext.setFormHistory((prevState) => prevState ? ([...prevState, data]) : null)
                apprContext.setApprFormRefrence(apprContext.apprForm);
                apprContext.findFlights();
                setSaveApiError("");
                if (formId == "-1") {
                    apprContext.setApprForm((prevState) => ({ ...prevState, id: data.apprFormId }));
                    apprContext.setApprFormRefrence((prevState) => ({ ...prevState, id: data.apprFormId }));
                    navigate.replace(`/appradmincreate/${apprContext.apprForm.flightDateLocal}/${apprContext.apprForm.dep}/${apprContext.apprForm.arr}/${apprContext.apprForm.flightNumber}/${data.apprFormId}`);
                }
            })
            .catch((error) => {
                !confirm ? setSaveIsLoading(false) : setConfirmIsLoading(false);
                setSaveApiError("Something Went Wrong !");
            });;
    }

    const fieldEmpty = (): boolean => {
        return !apprContext.apprForm.flightNumber || !apprContext.apprForm.dep ||
            !apprContext.apprForm.arr || !apprContext.apprForm.delayCategory || !apprContext.apprForm.flightType ||
            !apprContext.apprForm.delayTime || !apprContext.apprForm.fdReasonCode || !apprContext.apprForm.comments || !apprContext.apprForm.commentsFr;
    }

    const objectHasNotChanged = (): boolean => {
        return apprContext.apprForm.delayCategory == apprContext.apprFormRefrence.delayCategory &&
            apprContext.apprForm.flightType == apprContext.apprFormRefrence.flightType &&
            apprContext.apprForm.delayTime == apprContext.apprFormRefrence.delayTime &&
            apprContext.apprForm.voucherAmount == apprContext.apprFormRefrence.voucherAmount &&
            apprContext.apprForm.mcAmount == apprContext.apprFormRefrence.mcAmount &&
            apprContext.apprForm.fdReasonCode == apprContext.apprFormRefrence.fdReasonCode &&
            apprContext.apprForm.comments == apprContext.apprFormRefrence.comments &&
            apprContext.apprForm.commentsFr == apprContext.apprFormRefrence.commentsFr
    }


    return (
        <>

            {apprContext.mainApprLoading && <div className="container-fluid">
                <div className="row g-0 d-flex justify-content-center">
                    <div className="spinner-border m-5" role="status"> </div>
                </div>
            </div>}

            {!apprContext.mainApprApiError && !apprContext.mainApprLoading && <div className="container-fluid create-form mt-4">
                <div className="col">
                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Flight Date (local): </label>
                        </div>
                        <div className="col-sm-2">
                            <label className="col-form-label"> {getFormattedDate(new Date(apprContext.apprForm.flightDateLocal)) + ' -'} </label>
                            <label className="col-form-label"> {apprContext.apprFormStatus?.filter(elem => elem.id == apprContext.apprForm.statusId)[0].description}</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Flight Number: <span className="danger">*</span></label>
                        </div>
                        <div className="col-sm-2">
                            <input disabled type="text" value={apprContext.apprForm.flightNumber || ''} className="form-control form-control-sm" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Departure: <span className="danger">*</span> </label>
                        </div>
                        <div className="col-sm-2">
                            <input disabled type="text" value={apprContext.apprForm.dep || ''} className="form-control form-control-sm" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Arriving: <span className="danger">*</span> </label>
                        </div>
                        <div className="col-sm-2">
                            <input disabled type="text" value={apprContext.apprForm.arr || ''} id="inputPassword6" className="form-control form-control-sm" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Delay Category: <span className="danger">*</span></label>
                        </div>
                        <div className="col-sm-9">
                            {apprContext.aprDelayCategory && apprContext.aprDelayCategory.map((element, index) =>

                                <div className="form-check form-check-inline" key={index}>
                                    <input
                                        value={element.id || ''}
                                        onChange={(e) => apprContext.setApprForm((prevState) => ({ ...prevState, delayCategory: +e.target.value }))}
                                        checked={apprContext.apprForm.delayCategory == element.id}
                                        className="form-check-input"
                                        type="radio"
                                        name="delayCategory"
                                        disabled={props.readOnly} />
                                    <label className="form-check-label">
                                        {element.description}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Flight Type: <span className="danger">*</span></label>
                        </div>
                        <div className="col-sm-9">
                            {apprContext.aprFlightType && apprContext.aprFlightType.map((element, index) =>

                                <div className="form-check form-check-inline" key={index}>
                                    <input
                                        value={element.id || ''}
                                        onChange={(e) => apprContext.setApprForm((prevState) => ({
                                            ...prevState, flightType: +e.target.value,
                                            voucherAmount: apprContext.apprPayRate ? apprContext.apprPayRate.filter(elem => elem.flightType == +e.target.value && elem.delayTime == apprContext.apprForm?.delayTime && elem.compensationType == 2)[0]?.amount | 0 : prevState.voucherAmount,
                                            mcAmount: apprContext.apprPayRate ? apprContext.apprPayRate.filter(elem => elem.flightType == +e.target.value && elem.delayTime == apprContext.apprForm?.delayTime && elem.compensationType == 1)[0]?.amount | 0 : prevState.mcAmount,
                                        }))}
                                        checked={apprContext.apprForm.flightType === element.id}
                                        className="form-check-input"
                                        type="radio"
                                        name="flightType"
                                        disabled={props.readOnly} />
                                    <label className="form-check-label">
                                        {element.description}
                                    </label>
                                </div>
                            )}

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Delay Time: <span className="danger">*</span></label>
                        </div>
                        <div className="col-sm-9">
                            {apprContext.aprDelayTime && apprContext.aprDelayTime.map((element, index) =>

                                <div className="form-check form-check-inline" key={index}>
                                    <input
                                        value={element.id || ''}
                                        onChange={(e) => apprContext.setApprForm((prevState) =>
                                        ({
                                            ...prevState, delayTime: +e.target.value,
                                            voucherAmount: apprContext.apprPayRate ? apprContext.apprPayRate.filter(elem => elem.flightType == apprContext.apprForm?.flightType && elem.delayTime == +e.target.value && elem.compensationType == 2)[0]?.amount | 0 : prevState.voucherAmount,
                                            mcAmount: apprContext.apprPayRate ? apprContext.apprPayRate.filter(elem => elem.flightType == apprContext.apprForm?.flightType && elem.delayTime == +e.target.value && elem.compensationType == 1)[0]?.amount | 0 : prevState.mcAmount,
                                        }))}
                                        checked={apprContext.apprForm?.delayTime === element.id}
                                        className="form-check-input"
                                        type="radio"
                                        name="delayTime"
                                        disabled={props.readOnly} />
                                    <label className="form-check-label">
                                        {element.description}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Voucher Amount: <span className="danger">*</span></label>
                        </div>
                        <div className="col-sm-2">
                            <input disabled={props.readOnly} onChange={(e) => apprContext.setApprForm((prevState) => ({ ...prevState, voucherAmount: +e.target.value }))} type="number" min="0" value={apprContext.apprForm.voucherAmount} className="form-control form-control-sm" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Monetary Compensation Amount: <span className="danger">*</span></label>
                        </div>
                        <div className="col-sm-2">
                            <input disabled={props.readOnly} onChange={(e) => apprContext.setApprForm((prevState) => ({ ...prevState, mcAmount: +e.target.value }))} type="number" min="0" value={apprContext.apprForm.mcAmount} className="form-control form-control-sm" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Delay Reason: <span className="danger">*</span></label>
                        </div>
                        <div className="col-sm-6">
                            <select
                                disabled={props.readOnly}
                                className="form-select form-select-sm"
                                value={apprContext.apprForm.fdReasonCode || ""}
                                onChange={(e) => apprContext.setApprForm((prevState) =>
                                ({
                                    ...prevState,
                                    fdReasonCode: e.target.value,
                                    comments: apprContext.apprDelayReason ? apprContext.apprDelayReason.filter(elem => elem.code == e.target.value)[0].reasonEn : prevState.comments,
                                    commentsFr: apprContext.apprDelayReason ? apprContext.apprDelayReason.filter(elem => elem.code == e.target.value)[0].reasonFr : prevState.commentsFr
                                })
                                )}

                            >
                                {apprContext.apprDelayReason && apprContext.apprDelayReason.filter(elem => elem.flightTypeId == apprContext.apprForm.flightType).map((element, index) =>
                                    <option key={index} value={element.code || ''}>{element.reasonEn}</option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Reason (English): <span className="danger">*</span></label>
                        </div>
                        <div className="col-sm-6">
                            <input disabled={props.readOnly} onChange={(e) => apprContext.setApprForm((prevState) => ({ ...prevState, comments: e.target.value }))} value={apprContext.apprForm.comments} type="text" className="form-control form-control-sm" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <label className="col-form-label">Reason (French): <span className="danger">*</span></label>
                        </div>
                        <div className="col-sm-6">
                            <input disabled={props.readOnly} onChange={(e) => apprContext.setApprForm((prevState) => ({ ...prevState, commentsFr: e.target.value }))} value={apprContext.apprForm.commentsFr} type="text" className="form-control form-control-sm" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col mt-2 d-flex justify-content-sm-center">
                            <button disabled={props.readOnly || fieldEmpty() || objectHasNotChanged() || saveIsLoading} onClick={() => saveApprForm(false)} className="btn custom-button btn-sm me-2">
                                {saveIsLoading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                                {!saveIsLoading && <span>Save</span>}
                            </button>
                            {props.readOnly ?
                                <Link to={`/appradminpaxreadonly/${apprContext.apprForm.flightDateLocal}/${apprContext.apprForm.dep}/${apprContext.apprForm.arr}/${apprContext.apprForm.flightNumber}/${apprContext.apprForm.id}`} className="btn custom-button btn-sm me-2">Open Pax</Link> :
                                <Link to={`/appradminpax/${apprContext.apprForm.flightDateLocal}/${apprContext.apprForm.dep}/${apprContext.apprForm.arr}/${apprContext.apprForm.flightNumber}/${apprContext.apprForm.id}`} className="btn custom-button btn-sm me-2">Open Pax</Link>
                            }
                            {(apprContext.apprForm.statusId != 3 || confirmIsLoading) && <button disabled={props.readOnly || fieldEmpty() || confirmIsLoading || apprContext.apprForm.id == -1} onClick={() => apprContext.setApprForm((prevState) => ({ ...prevState, statusId: 3 }))} className="btn custom-button btn-sm me-2">
                                {confirmIsLoading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                                {!confirmIsLoading && <span>Confirm</span>}
                            </button>}
                            <button onClick={() => navigate.goBack()} className="btn custom-button btn-sm me-2">Cancel</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col mt-2 d-flex justify-content-sm-center">
                            <p className="danger"> {saveApiError} </p>
                        </div>
                    </div>


                </div>
            </div>}

            {!apprContext.mainApprApiError && !apprContext.mainApprLoading && apprContext.formHistory && (apprContext.formHistory.length > 0) &&
                <div className="container-fluid  mt-4">
                    <div className="row g-0 d-flex appr-create-table justify-content-center">
                        <div className="col">
                            <h5>History:</h5>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm">
                                    <thead className=" custom-color-head">
                                        <tr>
                                            <th scope="col">IssuedDate </th>
                                            <th scope="col">IssuedBy </th>
                                            <th scope="col">Processing </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {apprContext.formHistory.map((element, index) =>

                                            <tr key={index}>
                                                <td>{(new Date(element.submittedDate).toLocaleString())}</td>
                                                <td>{element.submittedBy} </td>
                                                <td>{element.processing} </td>
                                            </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>}

            {apprContext.mainApprApiError && <div className="container-fluid create-form mt-4">
                <div className="col">
                    <div className="row">
                        <div className="alert alert-danger" role="alert">
                            {apprContext.mainApprApiError}
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default ApprAdminCreate;
