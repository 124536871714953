
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';
import Menu from './components/Menu';
import Footer from './components/Footer';
import FlightDelayNotice from './components/FlightDelayNotice';
import AfdnForm from './components/AfdnForm';
import ApprAdmin from './components/ApprAdmin';
import ApprAdminPaxEdit from './components/ApprAdminPaxEdit';
import ApprAdminPax from './components/ApprAdminPax';
import ApprAdminCreate from './components/ApprAdminCreate';
import { ApprContextProvider } from './ApprStore/appr-context';
import { SwgContextProvider } from './ApprStore/swg-context';
import ApprBulkClaimProcessing from './components/ApprBulkClaimProcessing';
import FlightScheduleManagement from './components/FlightScheduleManagement';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { AfdnContextProvider } from './AfdnStore/afdn-context';
import EmailTemplate from './components/EmailTemplate';

const issuer = process.env.REACT_APP_ZENDESK_OKTA_ISSUER;
const clientId = process.env.REACT_APP_ZENDESK_OKTA_CLIENTID;
const redirect = `${window.location.origin}/callback`;

function App() {

  const oktaAuth = new OktaAuth({
    issuer: issuer,
    clientId: clientId,
    redirectUri: redirect
  });

  const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <SwgContextProvider>
          <SecureRoute exact path="/" component={FlightScheduleManagement} />
          <SecureRoute exact path="/FlightScheduleManagement" component={FlightScheduleManagement} />
        </SwgContextProvider>
        <ApprContextProvider>
          <AfdnContextProvider>
            <SecureRoute exact path="/flightdelaynotice" component={FlightDelayNotice} />
            <SecureRoute exact path="/afdnform/:aRoute/:date" component={AfdnForm} />
            <SecureRoute exact path="/flightdelaynoticereadonly" render={() => <FlightDelayNotice readOnly />} />
            <SecureRoute exact path="/afdnformreadonly/:aRoute/:date" render={() => <AfdnForm readOnly />}  />
            <SecureRoute exact path="/apprbulkclaimprocessing" component={ApprBulkClaimProcessing} />
            <SecureRoute exact path="/appradmin" component={ApprAdmin} />
            <SecureRoute exact path="/appradminCreate/:flightDateLocal/:dep/:arr/:flightNumber/:formId" component={ApprAdminCreate} />
            <SecureRoute exact path="/appradminPax/:flightDateLocal/:dep/:arr/:flightNumber/:formId" component={ApprAdminPax} />
            <SecureRoute exact path="/appradminPaxEdit/:formId/:apprFormPaxId" component={ApprAdminPaxEdit} />
            <SecureRoute exact path="/emailtemplate/:type/:emailId" component={EmailTemplate} />
            <SecureRoute exact path="/appradminreadonly" render={() => <ApprAdmin readOnly />} />
            <SecureRoute exact path="/appradminCreatereadonly/:flightDateLocal/:dep/:arr/:flightNumber/:formId" render={() => <ApprAdminCreate readOnly />} />
            <SecureRoute exact path="/appradminPaxreadonly/:flightDateLocal/:dep/:arr/:flightNumber/:formId" render={() => <ApprAdminPax readOnly />} />
            <SecureRoute exact path="/appradminPaxEditreadonly/:formId/:apprFormPaxId" render={() => <ApprAdminPaxEdit readOnly />} />
            <Route exact path="/callback" component={LoginCallback} />
          </AfdnContextProvider>
        </ApprContextProvider>
      </Security>
    </BrowserRouter>
  );
}

export default App;
