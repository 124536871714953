import './styles.css';
import { faFileText } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLocation from '../PageLocation';
import { Link } from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react";
import { IDelayFlightSearchResult, IFlightDelayFilter, IFlightDelayTimeDropdownItems } from '../../models';
import { useContext, useEffect, useState } from 'react';
import AfdnContext from '../../AfdnStore/afdn-context';

interface IProps {
  readOnly?: boolean;
}



const FlightDelayNotice = (props: IProps) => {

  const afdnContext = useContext(AfdnContext);


  useEffect(() => { afdnContext.getFlightDelayTimeDropdownItems() }, [])


  return (
    <>
      <div className="container-fluid d-flex justify-content-center filter mt-4">
        <div className="col p-3">
          <div className="row pb-4">
            <div className="col-sm-2">
              <label className="col-form-label">Departure Date: <span className="danger">*</span></label>
            </div>
            <div className="col-sm-3">
              <input
                type="date"
                className="form-control"
                value={afdnContext.flightDelayFilter.date}
                onChange={(e) => afdnContext.setFlightDelayFilter((prevState) => ({ ...prevState, date: e.target.value }))}
              />
            </div>
            {!props.readOnly &&
              <><div className="col-sm-2">
                <label className="col-form-label">Delay Time: </label>
              </div><div className="col-sm-3">
                  <select
                    className="form-select form-select-sm"
                    value={afdnContext.flightDelayFilter?.delayTime || ''}
                    onChange={(e) => afdnContext.setFlightDelayFilter((prevState) => ({ ...prevState, delayTime: +e.target.value }))}>
                    {afdnContext.flightDelayTimeDropdownItems && afdnContext.flightDelayTimeDropdownItems.map((element, index) => <option key={index} value={element.itemValue || ''}>{element.itemName}</option>
                    )}
                  </select>
                </div></>}
          </div>

          <div className="row">
            {!props.readOnly &&
              <><div className="col-sm-2">
                <label className="col-form-label">Aircraft Registration: </label>
              </div><div className="col-sm-3">
                  <select
                    className="form-select form-select-sm"
                    value={afdnContext.flightDelayFilter?.reg || ''}
                    onChange={(e) => afdnContext.setFlightDelayFilter((prevState) => ({ ...prevState, reg: e.target.value }))}>
                    <option value={''}>Select</option>
                    {afdnContext.delayFlightList && afdnContext.delayFlightList.filter((e: IDelayFlightSearchResult) => e.aircraft.trim() != '').map((element, index) => <option key={index} value={element.aircraft}>{element.aircraft}</option>
                    )}
                  </select>
                </div></>}
            <div className="col-sm-2">
              <label className="col-form-label">Flight Number: </label>
            </div>
            <div className="col-sm-3">
              <input
                className="form-control"
                value={afdnContext.flightDelayFilter.flightNumber}
                onChange={(e) => afdnContext.setFlightDelayFilter((prevState) => ({ ...prevState, flightNumber: e.target.value }))}
              />            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 mt-4">
              <button disabled={!afdnContext.flightDelayFilter.date || afdnContext.searchIsloading} onClick={afdnContext.getFlights} className="btn custom-button me-1">
                {afdnContext.searchIsloading && <span><span className="spinner-border spinner-border-sm me-1"></span>Loading...</span>}
                {!afdnContext.searchIsloading && <span>Search</span>}
              </button>
              <button disabled={afdnContext.searchIsloading} onClick={afdnContext.resetFilters} className="btn custom-button me-1">Reset Filter</button>
            </div>
          </div>
        </div>
      </div>



      <div className="container-fluid mt-4">
        <div className="row g-0 d-flex table justify-content-center">
          <div className="col p-0">

            {afdnContext.delayFlightListFiltered && !afdnContext.delayFlightListFiltered.length && afdnContext.flightDelayFilter.date && !afdnContext.searchError &&
              <div className="alert alert-warning">No flight found</div>}
            {afdnContext.searchError && <div className="alert alert-danger">{afdnContext.searchError}</div>}
            {afdnContext.delayFlightListFiltered && afdnContext.delayFlightListFiltered.length != 0 && !afdnContext.searchError &&
              <div className="table-responsive">
                <table className="table table-striped table-sm" >
                  <thead className="custom-color-head">
                    <tr>
                      <th scope="col">Registration Number Of Aircraft</th>
                      <th scope="col">Routing</th>
                      <th scope="col">Flight Number</th>
                      <th scope="col">Status</th>
                      <th scope="col">Open</th>
                      <th scope="col">Synopsis Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {afdnContext.delayFlightListFiltered && afdnContext.delayFlightListFiltered.map((flight, index) =>
                      <tr key={index}>
                        <td>{flight.aircraft}</td>
                        <td>{flight.routing} </td>
                        <td>{flight.flightNumber}</td>
                        <td>{flight.status}</td>
                        {props.readOnly ?
                          <td><Link className="h6" to={`/afdnformreadonly/${flight.aroute}/${flight.departureDate}`}> <FontAwesomeIcon icon={faFileText} /> </Link></td> :
                          <td><Link className="h6" to={`/afdnform/${flight.aroute}/${flight.departureDate}`}> <FontAwesomeIcon icon={faFileText} /> </Link></td>
                        }
                        <td>{flight.synopsisStatus}</td>
                      </tr>)}
                  </tbody>
                </table>
              </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default FlightDelayNotice;
