import React, { useState, useEffect, SetStateAction } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { IFlightDetails, IFlightSchedule } from "../models";
import { waitFor } from "@testing-library/react";
import { getFormattedDate } from "../utility/Utility";

interface IProps {
  children?: React.ReactNode;

}

export type swgContextType = {
  //Flight Details
  dateInput: string | null;
  flightsDetails: IFlightDetails[] | null;
  flightNumbers: string[] | null;
  flightRoutes: string[];
  flightRoute: string;
  RAHflights: IFlightSchedule | null;
  setDateInput: (value: SetStateAction<string | null>) => void;
  getFlightDetails: (depFromDate: string | null | undefined, flightNum: string) => void;
  setFlightsDetails: (value: SetStateAction<IFlightDetails[] | null>) => void;
  getFlightNumbers: (depFromDate: string | null | undefined) => void;
  setFlightNumbers: (value: []) => void;
  setFlightRoute: (value: SetStateAction<string>) => void;
  setFlightRoutes: (value: []) => void;
  swgFlightsLoading: boolean;
  swgFlightsApiError: string;
  getRahFlights: (depFromDate: string, flightNumber: string, depAirportCode: string, arrAirportCode: string) => void;
  setRahFlights: (value: SetStateAction<IFlightSchedule | null>) => void;
  setRevisedArrDate: (value: SetStateAction<string>) => void;
  revisedArrDate: string;
  setRevisedDepDate: (value: SetStateAction<string>) => void;
  revisedDepDate: string;
  SaveFlightSchedule: () => void;
  setCanceled: (value: boolean) => void;
  isCanceled: boolean;
  onDeleteFlightSch: (value: Number) => void;
  rahID: number;
  setResultMsg: (value: SetStateAction<string>) => void;
  resultMsg: string,
  setResultMsgCss: (value: SetStateAction<string>) => void;
  resultMsgCss: string,
  setSwgFlightsLoading: (value: SetStateAction<boolean>) => void;
  setFlydetails: (value: SetStateAction<IFlightDetails | null>) => void;
  flydetails: IFlightDetails | null;
  setFlightNumber:(value: string) => void;
  flightNumber: string;
  setSaveSuccess:(value: boolean) => void;
  SaveSuccess: boolean;

};

const defaultContext = {
  //Appr Flight Search
  flightsDetails: null,
  flightNumbers: null,
  flightRoutes: [],
  dateInput: null,
  swgFlightsLoading: false,
  swgFlightsApiError: "",
  flightRoute: "",
  RAHflights: null,
  setDateInput: () => { },
  setFlightsDetails: () => { },
  getFlightDetails: () => { },
  getFlightNumbers: () => { },
  setFlightRoute: () => { },
  setFlightRoutes: () => { },
  getRahFlights: () => { },
  setRahFlights: () => { },
  setFlightNumbers: () => { },
  setRevisedArrDate: () => { },
  revisedArrDate: "",
  setRevisedDepDate: () => { },
  revisedDepDate: "",
  SaveFlightSchedule: () => { },
  setCanceled: () => { },
  isCanceled: false,
  onDeleteFlightSch: () => { },
  rahID: 0,
  setResultMsg: () => { },
  resultMsg: "",
  setResultMsgCss: () => { },
  resultMsgCss: "",
  setSwgFlightsLoading: () => { },
  setFlydetails: () => { },
  flydetails: null,
  setFlightNumber:() => { },
  flightNumber:"",
  setSaveSuccess:() => { },
  SaveSuccess: false

};
const swgContext = React.createContext<swgContextType>(defaultContext);

export const SwgContextProvider = (props: IProps) => {
  //Appr Flights Search
  const [flightsDetails, setFlightsDetails] = useState<IFlightDetails[] | null>(null);
  const [flydetails, setFlydetails] = useState<IFlightDetails | null>(null);
  const [startDate, setStartDate] = useState<Date | null | undefined>(new Date());
  const [flightNumbers, setFlightNumbers] = useState([]);
  const [flightNumber, setFlightNumber] = useState("Flight");
  const [dateInput, setDateInput] = useState<string | null>(null);
  const [swgFlightsLoading, setSwgFlightsLoading] = useState(false);
  const [swgFlightsApiError, setSwgFlightsApiError] = useState<string>("");
  const [flightRoutes, setFlightRoutes] = useState([]);
  const [flightRoute, setFlightRoute] = useState("Flight Route");
  const [RAHflights, setRahFlights] = useState<IFlightSchedule | null>(null);
  const [revisedArrDate, setRevisedArrDate] = useState("");
  const [revisedDepDate, setRevisedDepDate] = useState("");
  const [isCanceled, setCanceled] = useState(false);
  const [flightDate, setFlightDate] = useState("");
  const [rahID, setRahID] = useState(Number);
  const [typeId, setTypeId] = useState(Number);
  const [resultMsg, setResultMsg] = useState<string>("");
  const [resultMsgCss, setResultMsgCss] = useState<string>("");
  const [SaveSuccess, setSaveSuccess] = useState(false);
  const { authState,oktaAuth } = useOktaAuth();

  const getFlightNumbers = async (depFromDate: string | null | undefined) => {
    const apiFlightNumberEnd = `${process.env.REACT_APP_AIR_PRC_API_URL}flights/FlightNumbers?date=${dateInput}`
    await fetch(apiFlightNumberEnd, {
      headers: {
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setFlightNumbers(data);
      })      
      .catch(err => console.log("Error:", err));


  };

  const getFlightDetails = (depFromDate: string | null | undefined, flightNum: string) => {
    const apiFlightDetailEnd = `${process.env.REACT_APP_AIR_PRC_API_URL}flightStatus?date=${depFromDate}&flightno=${flightNum}`;
    fetch(
      `${process.env.REACT_APP_AIR_PRC_API_URL}flightStatus?date=${depFromDate}&flightno=${flightNum}`,
      {
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        method: "GET",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Something went wrong! Please try again");
        }
        return res.json();
      })
      .then((data) => {
        setSwgFlightsLoading(false);
        data && setFlightsDetails(data);
        const routes = data?.map((fly: { gateway: string; destination: string; }) => fly.gateway + "-" + fly.destination);
        setFlightRoutes(routes);
        if (routes)
          setFlightRoute(routes[0]);

        const flyRoute = routes[0].split("-");
        let fly = data?.filter((f: { gateway: any; destination: any; }) => f.gateway === flyRoute[0] && f.destination === flyRoute[1]);
        fly && setFlydetails(fly[0]);
        setSwgFlightsApiError("");
      })
      .catch((error) => {
        setSwgFlightsApiError("Something Went Wrong !");
      });
  };

  const getRahFlights = (depFromDate: string, flightNumber: string, depAirportCode: string, arrAirportCode: string) => {

    // setSwgFlightsLoading(true);
    const flno = flightNumber?.substring(2, flightNumber?.length);
    const carrier = flightNumber?.trim().substring(0,2);
    const apiFlightDetailEnd = `${process.env.REACT_APP_AIR_PRC_API_URL}flightschedulemanagement?date=${depFromDate}&flightNumber=${flno}&arrAirportCode=${arrAirportCode}&depAirportCode=${depAirportCode}&carrier=${carrier}`;
    fetch(apiFlightDetailEnd,
      {
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        method: "GET",
      }
    ).then((response) => {
      if (!response.ok) {
        return Promise.reject();
      }
      return response.json();
    })
      .then((dataRah: IFlightSchedule) => {
        setSwgFlightsLoading(false);
        if (!dataRah.flightNumber) {
          let fly = flightsDetails?.filter((f) => f.gateway === flightRoute.split("-")[0] && f.destination === flightRoute.split("-")[1])[0];
          if (fly !== null && fly !== undefined) {
            const RevDepDt = getFormattedDate(new Date(fly?.revisedDepDateTime));
            const RevArrDt = getFormattedDate(new Date(fly?.revisedArrDateTime));
            const lenDep = fly?.revisedDepDateTime.length;
            const lenArr = fly?.revisedArrDateTime.length;
            const RevDepTime = fly?.revisedDepDateTime.substring(11,lenDep);
            const RevArrTime = fly?.revisedArrDateTime.substring(11,lenArr);
            dataRah.revisedDepDateTime = RevDepDt + "T" + RevDepTime;
            dataRah.revisedArrDateTime = RevArrDt + "T" + RevArrTime;
            dataRah.flightNumber = fly?.flightNo.substring(2, fly?.flightNo.length);
            dataRah.carrier = fly?.flightNo.substring(0, 2);
            dataRah.flightDate = fly?.date;
            dataRah.depAirportCode = fly.gateway;
            dataRah.arrAirportCode = fly.destination;
            dataRah.id = 0;
            dataRah.updatedDate = "NEW";

            setRevisedDepDate(dataRah.revisedDepDateTime);
            setRevisedArrDate(dataRah.revisedArrDateTime);
            setFlightDate(fly?.date);
          }
          setRahFlights(dataRah);

        }
        else {
          dataRah && setTypeId(dataRah.typeId);
          dataRah && setRahID(dataRah.id);
          dataRah && setCanceled(dataRah.canceled);
          const DepDt = getFormattedDate(new Date(dataRah.revisedDepDateTime));
          const ArrDt = getFormattedDate(new Date(dataRah.revisedArrDateTime));
          const DepTime = dataRah.revisedDepDateTime.split("T")[1];
          const ArrTime = dataRah.revisedArrDateTime.split("T")[1];
          setRevisedDepDate(DepDt + "T" + DepTime);
          setRevisedArrDate(ArrDt + "T" + ArrTime);
          dataRah.updatedDate = getFormattedDate(new Date(dataRah.updatedDate));
          setRahFlights(dataRah);

        }
        setSwgFlightsApiError("");


      })
      .catch((error) => {
        setSwgFlightsLoading(false);
        setSwgFlightsApiError("Something Went Wrong !");
      });

  };
  const SaveFlightSchedule = () => {

    let Flsch: IFlightSchedule = {
      "id": RAHflights !== null ? RAHflights.id : 0,
      "typeId": RAHflights !== null ? RAHflights.typeId : 0,
      "flightDate": RAHflights !== null ? RAHflights.flightDate : "",
      "carrier": RAHflights !== null ? RAHflights.carrier : "",
      "flightNumber": RAHflights !== null ? RAHflights?.flightNumber : "",
      "depAirportCode": RAHflights !== null ? RAHflights?.depAirportCode : "",
      "arrAirportCode": RAHflights !== null ? RAHflights?.arrAirportCode : "",
      "canceled": isCanceled,
      "revisedDepDateTime": revisedDepDate,
      "revisedArrDateTime": revisedArrDate,
      "updatedDate": getFormattedDate(new Date()),
      "updatedBy": authState?.idToken?.claims['email'] || "",
      "updatedByUsername":  authState?.idToken?.claims['email'] || ""
    }
    const apiFlightschSavEnd = `${process.env.REACT_APP_AIR_PRC_API_URL}flightschedulemanagement`
    fetch(apiFlightschSavEnd, {
      method: 'POST',
      body: JSON.stringify(Flsch),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },

    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        setResultMsgCss("col-form-label floatRight formsucess");
        setResultMsg("Flight details saved.");
        setSaveSuccess(true);
        return response.json();
      })
      .then((data) => {
      });

  };
  const onDeleteFlightSch = async (FlId: Number) => {
    const apiFlightDeleteEnd = `${process.env.REACT_APP_AIR_PRC_API_URL}flightschedulemanagement/${FlId}`;
    await fetch(apiFlightDeleteEnd, {
      headers: {
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        setResultMsgCss("col-form-label floatRight formsucess");
        setResultMsg("Flight details deleted.");
        setFlightNumber("Flight");
        setFlightRoutes([]);
        setFlydetails(null);
        return response.json();
      })
      .then((data: boolean) => {
      })
      .catch(err => {
        console.log("Error:", err);
      });
  };
  return (
    <swgContext.Provider
      value={{
        //search appr flights
        dateInput,
        setDateInput,
        flightsDetails,
        setFlightsDetails,
        setFlightRoute,
        setFlightRoutes,
        flightNumbers,
        getFlightDetails,
        getFlightNumbers,
        swgFlightsLoading,
        swgFlightsApiError,
        flightRoute,
        flightRoutes,
        getRahFlights,
        setRahFlights,
        RAHflights,
        setFlightNumbers,
        setRevisedArrDate,
        revisedArrDate,
        setRevisedDepDate,
        revisedDepDate,
        SaveFlightSchedule,
        setCanceled,
        isCanceled,
        onDeleteFlightSch,
        rahID,
        setResultMsg,
        resultMsg,
        setResultMsgCss,
        resultMsgCss,
        setSwgFlightsLoading,
        setFlydetails,
        flydetails,
        setFlightNumber,
        flightNumber,
        setSaveSuccess,
        SaveSuccess,
      }}
    >
      {props.children}
    </swgContext.Provider>
  );
};

export default swgContext;
